import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Decrypt_Id_Name, DecryptedList, base64ToString, filterPassedDateTime, filterPassedTime, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate, ScreenPermision } from '../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import { convertToHTML } from 'draft-convert';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { get_AgencyOfficer_Data } from '../../../../../redux/actions/IncidentAction';
import { get_Narrative_Type_Drp_Data } from '../../../../../redux/actions/DropDownsData';
import ChangesModal from '../../../../Common/ChangesModal';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const Narrative = (props) => {

  const { incidentReportedDate } = props

  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useQuery();
  var IncID = query?.get("IncId");
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));

  const dispatch = useDispatch()
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
  const narrativeTypeDrpData = useSelector((state) => state.DropDown.narrativeTypeDrpData);

  const { get_IncidentTab_Count, setChangesStatus, changesStatus, } = useContext(AgencyContext);

  const [clickedRow, setClickedRow] = useState(null);
  const [narrativeData, setNarrativeData] = useState([]);
  const [upDateCount, setUpDateCount] = useState(0);
  const [status, setStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [narrativeID, setNarrativeID] = useState('');
  const [loder, setLoder] = useState(false);
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [incidentID, setIncidentID] = useState('');
  const [loginPinID, setLoginPinID] = useState();
  const [userName, setUserName] = useState('');
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);

  const [value, setValue] = useState({
    'CommentsDoc': '',
    'IncidentId': '',
    'NarrativeID': '',
    'Comments': '',
    'ReportedByPINActivityID': null, 'NarrativeTypeID': null,
    'AsOfDate': null,
    'CreatedByUserFK': '',
    'ModifiedByUserFK': '',
  })

  const [errors, setErrors] = useState({
    'ReportedByPinError': '', 'AsOfDateError': '', 'NarrativeIDError': '', 'CommentsError': '',
  })

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(parseInt(localStoreData?.PINID)); setUserName(localStoreData?.UserName)
      setValue({ ...value, 'CreatedByUserFK': localStoreData?.PINID });
      if (narrativeTypeDrpData?.length === 0) { dispatch(get_Narrative_Type_Drp_Data(loginAgencyID)) }
      dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID, IncID))
      getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID)
    }
  }, [localStoreData, IncID]);

  useEffect(() => {
    if (incidentID) {
      setValue(pre => {
        return {
          ...pre, 'ReportedByPINActivityID': checkId(loginPinID, agencyOfficerDrpData) ? loginPinID : '', 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID,
          'CommentsDoc': '',
          'NarrativeID': '',
          'Comments': '',
          'ReportedByPINActivityID': '', 'NarrativeTypeID': '',
          'AsOfDate': '',
        }
      })
    }
  }, [incidentID, status, agencyOfficerDrpData]);

  const checkId = (id, obj) => {
    const status = obj?.filter((item) => item?.value == id)
    return status?.length > 0
  }


  const [editval, setEditval] = useState();
  const [narrativeTypeList, setNarrativeTypeList] = useState([])

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    if (IncID) {
      setIncidentID(IncID); get_NarrativesData(IncID);
      //  get_IncidentTab_Count(IncID); 
    }
  }, [IncID,]);

  useEffect(() => {
    if (incidentID) {
      setValue({ ...value, 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID, 'ReportedByPINActivityID': loginPinID, })
    }
  }, [incidentID, upDateCount]);

  // useEffect(() => {
  // if (loginAgencyID) {
  // Get_Officer_Name(loginAgencyID); getScreenPermision(loginAgencyID, loginPinID) get_Narrative_Type(loginAgencyID);
  // }
  // }, [loginAgencyID])

  const GetSingleData = (NarrativeID) => {
    const val = { 'NarrativeID': NarrativeID }
    fetchPostData('Narrative/GetSingleData_Narrative', val)
      .then((res) => {
        if (res) setEditval(res)
        else setEditval()
      })
  }

  useEffect(() => {
    if (status) {
      setValue({
        ...value,
        'AsOfDate': editval[0].AsOfDate ? getShowingDateText(editval[0].AsOfDate) : null,
        'NarrativeID': editval[0].NarrativeID,
        'NarrativeTypeID': editval[0].NarrativeTypeID,
        'ReportedByPINActivityID': editval[0].ReportedByPINActivityID,
        'Comments': editval[0].Comments ? editval[0].Comments?.trim() : '',
        'ModifiedByUserFK': loginPinID,
        'CommentsDoc': editval[0].CommentsDoc,
      });
      if (editval[0].CommentsDoc?.trim()) {
        // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(editval[0].CommentsDoc ? editval[0].CommentsDoc?.trim() : <p></p>))));
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(editval[0].CommentsDoc ? editval[0].CommentsDoc?.trim()?.replace(/\\/g, '') : <p></p>))));
      }
    } else {
      setValue({
        ...value,
        'Comments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'NarrativeID': '', 'ReportedByPINActivityID': loginPinID,
        'NarrativeTypeID': null, 'AsOfDate': null,
      });
      setEditorState(() => EditorState.createEmpty(),);
    }
  }, [editval])

  const get_Narrative_Type = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('NarrativeType/GetDataDropDown_NarrativeType', val)
      .then((res) => {
        if (res) setNarrativeTypeList(Comman_changeArrayFormat(res, 'NarrativeTypeID', 'Description'))
        else setNarrativeTypeList([])
      })
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      reset()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const ChangeDropDown = (e, name) => {
    setStatesChangeStatus(true); setChangesStatus(true);
    if (e) {
      setValue({
        ...value, [name]: e.value
      })
    } else {
      setValue({
        ...value, [name]: null
      })
    }
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML(state);
  }

  const convertContentToHTML = (state) => {
    // let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    // setValue({ ...value, 'CommentsDoc': currentContentAsHTML })ttt

    let currentContentAsHTML = draftToHtml(convertToRaw(state.getCurrentContent()));

    setValue({ ...value, 'CommentsDoc': currentContentAsHTML })
  }

  // const getValueNarrative = (e) => {
  //   setStatesChangeStatus(true); setChangesStatus(true);
  //   for (let key in e.blocks) {
  //     if (e.blocks[key]?.text) {
  //       setValue({ ...value, ['Comments']: e.blocks[key]?.text })
  //     }
  //   }
  //   // setValue({
  //   //   ...value,
  //   //   ['Comments']: e.blocks[0].text
  //   // });
  // }

  const getValueNarrative = (e) => {
    setStatesChangeStatus(true); setChangesStatus(true);
    for (let key in e.blocks) {
      let combinedText = '';
      if (e.blocks[key]?.text) {
        combinedText += e.blocks[key].text + ' ';
      }
      setValue({ ...value, ['Comments']: combinedText })
    }
  }

  const submit = () => {
    const result = narrativeData?.find(item => {
      if (item.Comments) {
        if (item.Comments.toLowerCase() === value.Comments.toLowerCase()) {
          return item.Comments.toLowerCase() === value.Comments.toLowerCase()
        } else return item.Comments.toLowerCase() === value.Comments.toLowerCase()
      }
    });
    if (result) {
      toastifyError(' Already Exists');
      setErrors({ ...errors, ['AsOfDateError']: '' })
    } else {
      AddDeleteUpadate('Narrative/Insert_Narrative', value)
        .then((res) => {
          get_NarrativesData(incidentID);
          get_IncidentTab_Count(incidentID);
          const parsedData = JSON.parse(res.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          setStatusFalse(); setStatesChangeStatus(false); setChangesStatus(false);
          setErrors({ ...errors, ['AsOfDateError']: '', });

        })
    }
  }

  const updateNarrative = (e) => {
    const result = narrativeData?.find(item => {
      if (item.Comments) {
        if (item.NarrativeID != value.NarrativeID) {
          if (item.Comments.toLowerCase() === value.Comments.toLowerCase()) {
            return item.Comments.toLowerCase() === value.Comments.toLowerCase()
          } else return item.Comments.toLowerCase() === value.Comments.toLowerCase()
        }
      }
    });
    if (result) {
      toastifyError('Comments Already Exists')
      setErrors({ ...errors, ['AsOfDateError']: '' })
    } else {
      AddDeleteUpadate('Narrative/Update_Narrative', value)
        .then((res) => {
          const parsedData = JSON.parse(res.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          setStatus(true); setStatesChangeStatus(false);
          setStatusFalse(); setChangesStatus(false);
          // setErrors({ ...errors, ['AsOfDateError']: '', });
          setErrors({ ...errors, 'ReportedByPinError': '', 'AsOfDateError': '', 'NarrativeIDError': '', 'CommentsError': '', });

          get_NarrativesData(incidentID);
        })
    }
  }

  const reset = () => {
    setValue({
      ...value,
      'NarrativeTypeID': '', 'ReportedByPINActivityID': loginPinID, 'Comments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'NarrativeID': '', 'AsOfDate': null,
    });
    setErrors({
      ...errors,
      'ReportedByPinError': '', 'AsOfDateError': '', 'NarrativeIDError': '', 'CommentsError': '',
    });
    setEditorState(() => EditorState.createEmpty(),); setStatesChangeStatus(false); setChangesStatus(false);
  }

  const check_Validation_Error = () => {
    if (RequiredFieldIncident(value.ReportedByPINActivityID)) {
      setErrors(prevValues => { return { ...prevValues, ['ReportedByPinError']: RequiredFieldIncident(value.ReportedByPINActivityID) } })
    }
    if (RequiredFieldIncident(value.AsOfDate)) {
      setErrors(prevValues => { return { ...prevValues, ['AsOfDateError']: RequiredFieldIncident(value.AsOfDate) } })
    }
    if (RequiredFieldIncident(value.NarrativeTypeID)) {
      setErrors(prevValues => { return { ...prevValues, ['NarrativeIDError']: RequiredFieldIncident(value.NarrativeTypeID) } })
    }
    if (RequiredFieldIncident(value.Comments?.trim())) {
      setErrors(prevValues => { return { ...prevValues, ['CommentsError']: RequiredFieldIncident(value.Comments?.trim()) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { ReportedByPinError, AsOfDateError, NarrativeIDError, CommentsError, } = errors

  useEffect(() => {
    if (ReportedByPinError === 'true' && AsOfDateError === 'true' && NarrativeIDError === 'true' && CommentsError === 'true') {
      if (status) updateNarrative()
      else submit()
    }
  }, [ReportedByPinError, AsOfDateError, NarrativeIDError, CommentsError,])

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }

  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  const get_NarrativesData = (incidentID) => {
    const val = { IncidentId: incidentID }
    fetchPostData('Narrative/GetData_Narrative', val).then(res => {
      if (res) {
        setNarrativeData(res); setLoder(true)
      } else {
        setNarrativeData([]); setLoder(true)
      }
    })
  }

  const getScreenPermision = (LoginAgencyID, LoginPinID) => {
    ScreenPermision("I032", LoginAgencyID, LoginPinID).then(res => {
      if (res) {
        setEffectiveScreenPermission(res)
      } else {
        setEffectiveScreenPermission([])
      }
    });
  }

  const columns = [
    {
      width: '150px',
      name: 'Date',
      selector: (row) => getShowingDateText(row.AsOfDate),
      sortable: true
    },
    {
      width: '250px',
      name: 'Narrative',
      // selector: (row) => <>{row?.Comments ? row?.Comments?.trim()?.substring(0, 60) : ''}{row?.Comments?.trim()?.length > 60 ? '  . . .' : null} </>,
      selector: (row) => row?.Comments || '',
      format: (row) => (
        <>{row?.Comments ? row?.Comments.substring(0, 70) : ''}{row?.Comments?.length > 40 ? '  . . .' : null} </>
      ),
      sortable: true
    },
    {
      width: '230px',
      name: 'Reported By',
      selector: (row) => row.ReportedBy_Description,
      sortable: true
    },
    {
      name: 'Type',
      selector: (row) => row.NarrativeDescription,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 15 }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 15 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <span onClick={(e) => setNarrativeID(row.NarrativeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
              : <></>
              : <span onClick={(e) => setNarrativeID(row.NarrativeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
          }
        </div>
    }
  ]

  const editNarratives = (val) => {
    if (val) {
      setStatesChangeStatus(false);
      setNarrativeID(val.NarrativeID); GetSingleData(val.NarrativeID)
      setUpDateCount(upDateCount + 1);
      setStatus(true); setModal(true);
      setErrors({ ...errors, 'ReportedByPinError': '', 'AsOfDateError': '', 'NarrativeIDError': '', 'CommentsError': '', });
    }
  }

  const setStatusFalse = (e) => {
    setClickedRow(null);
    setStatus(false);
    setModal(true);
    reset();
  }

  const DeleteNarratives = () => {
    const val = { 'narrativeID': narrativeID, 'DeletedByUserFK': loginPinID, }
    AddDeleteUpadate('Narrative/Delete_Narrative', val).then((res) => {
      if (res.success) {
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        get_IncidentTab_Count(incidentID); setStatusFalse()
      } else { console.log("Somthing Wrong"); }
      get_NarrativesData(incidentID);
    })
  }

  const reportedTime = new Date(incidentReportedDate);
  let reportDate = reportedTime.getDate();

  // const filterPassedNarrative = (time) => {
  //   const selectedDate = new Date(time);
  //   const currentDate = new Date();
  //   if (value?.AsOfDate && new Date(value?.AsOfDate)?.getDate() === reportDate && new Date(currentDate)?.getDate() != reportDate) {
  //     return reportedTime.getTime() <= selectedDate.getTime();
  //   } else if (new Date(currentDate)?.getDate() === reportDate) {
  //     return reportedTime.getTime() <= selectedDate.getTime() && currentDate.getTime() >= selectedDate.getTime();
  //   }
  //   else {
  //     return currentDate.getTime() > selectedDate.getTime();
  //   }
  // };

  return (
    <>
      <div className="row mt-1">
        <div className="col-12 col-md-12 col-lg-12 px-0 pl-0">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onChange={getValueNarrative}
            editorStyle={{ height: '15vh', }}
            toolbar={{
              options: ['inline', 'blockType', 'fontFamily', 'list', 'textAlign', 'history',],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline', 'monospace',],
              },
              list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['unordered', 'ordered', 'indent', 'outdent'],
                // unordered: { icon: unordered, className: undefined },
                // ordered: { icon: ordered, className: undefined },
                // indent: { icon: indent, className: undefined },
                // outdent: { icon: outdent, className: undefined },
              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['left', 'center', 'right', 'justify'],
              },
              colorPicker: {
                icon: 'color',
                className: undefined,
                component: undefined,
                popupClassName: undefined,
                colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                  'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                  'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                  'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                  'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                  'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
              },
            }}
          />
          {errors.CommentsError !== 'true' ? (
            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CommentsError}</span>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Officer Name{errors.ReportedByPinError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReportedByPinError}</p>
                ) : null}</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='ReportedByPINActivityID'
                  isClearable
                  styles={colourStyles}
                  value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.ReportedByPINActivityID)}
                  options={agencyOfficerDrpData}
                  onChange={(e) => ChangeDropDown(e, 'ReportedByPINActivityID')}
                  placeholder="Select.."
                  menuPlacement="top"
                />

              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Report Type  {errors.NarrativeIDError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NarrativeIDError}</p>
                ) : null}</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='NarrativeTypeID'
                  isClearable
                  styles={colourStyles}
                  value={narrativeTypeDrpData?.filter((obj) => obj.value === value?.NarrativeTypeID)}
                  options={narrativeTypeDrpData}
                  onChange={(e) => ChangeDropDown(e, 'NarrativeTypeID')}
                  placeholder="Select.."
                  menuPlacement="top"
                />

              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Approving Supervisor</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 text-field ">
                <input type="text" className='readonlyColor' name='status' disabled readOnly />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Date/Time {errors.AsOfDateError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AsOfDateError}</p>
                ) : null}</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <DatePicker
                  ref={startRef}
                  onKeyDown={(e) => {
                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                      e.preventDefault();
                    } else {
                      onKeyDown(e);
                    }
                  }
                  }
                  dateFormat="MM/dd/yyyy HH:mm"
                  timeInputLabel
                  isClearable={value?.AsOfDate ? true : false}
                  className='requiredColor'
                  name='AsOfDate'
                  onChange={(date) => {
                    if (date) {
                      setStatesChangeStatus(true);
                      if (date >= new Date()) {
                        setChangesStatus(true)
                        setValue({ ...value, ['AsOfDate']: new Date() ? getShowingMonthDateYear(new Date()) : null })
                      } else if (date <= new Date(incidentReportedDate)) {
                        setChangesStatus(true)
                        setValue({ ...value, ['AsOfDate']: incidentReportedDate ? getShowingMonthDateYear(incidentReportedDate) : null })
                      } else {
                        setChangesStatus(true)
                        setValue({ ...value, ['AsOfDate']: date ? getShowingMonthDateYear(date) : null })
                      }
                    } else {
                      setStatesChangeStatus(true);
                      setChangesStatus(true)
                      setValue({ ...value, ['AsOfDate']: null })
                    }
                  }}
                  selected={value?.AsOfDate && new Date(value?.AsOfDate)}
                  placeholderText={'Select...'}
                  showTimeSelect
                  timeIntervals={1}
                  timeCaption="Time"
                  popperPlacement="top-end"
                  maxDate={new Date()}
                  minDate={new Date(incidentReportedDate)}
                  autoComplete="Off"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  filterTime={(time) => filterPassedDateTime(time, value?.AsOfDate, incidentReportedDate)}
                />

              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Status</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 text-field ">
                <input type="text" className='readonlyColor' name='status' disabled readOnly />
              </div>
              <div className="col-12 col-md-12 col-lg-12 text-right mt-2  bb">
                <button type="button" className="btn btn-sm btn-success mr-1 mb-2" onClick={() => { setStatusFalse(); }}>New</button>
                {
                  status ?
                    effectiveScreenPermission ?
                      effectiveScreenPermission[0]?.Changeok ?
                        <button type="button" disabled={!statesChangeStatus} onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2 mb-2">Update</button>
                        :
                        <>
                        </>
                      :
                      <button type="button" disabled={!statesChangeStatus} onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2 mb-2">Update</button>
                    :
                    effectiveScreenPermission ?
                      effectiveScreenPermission[0]?.AddOK ?
                        <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2 mb-2">Save</button>
                        :
                        <>
                        </>
                      :
                      <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2 mb-2">Save</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 px-0 mt-1" >
        {
          loder ?
            <DataTable
              showHeader={true}
              persistTableHead={true}
              dense
              columns={columns}
              data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? narrativeData : '' : narrativeData}
              highlightOnHover
              customStyles={tableCustomStyles}
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={(row) => {
                setClickedRow(row);
                editNarratives(row);
              }}
              pagination
              paginationPerPage={'100'}
              paginationRowsPerPageOptions={[100, 150, 200, 500]}
              showPaginationBottom={100}
              fixedHeaderScrollHeight='120px'
              fixedHeader
              noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
            />
            :
            <Loader />
        }
      </div>
      <IdentifyFieldColor />
      <ChangesModal func={check_Validation_Error} />
      <DeletePopUpModal func={DeleteNarratives} />
    </>
  )
}

export default Narrative;


