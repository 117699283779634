import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Decrypt_Id_Name, base64ToString, changeArrayFormat, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, tableCustomStyles } from '../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../hooks/Api';
import { Comman_changeArrayFormat, changeArray, fourColArray, sixColArray, threeColArray, threeColArrayWithCode } from '../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { AgencyContext } from '../../../Context/Agency/Index';
import { RequiredFieldIncident, Space_Allow_with_Trim } from '../Utility/Personnel/Validation';
import { Email_Field, NameValidationCharacter, PhoneFieldNotReq, RequiredField } from '../Agency/AgencyValidation/validators';
import { Comparision, Heights, SSN_Field } from '../PersonnelCom/Validation/PersonnelValidation';
import SelectBox from '../../Common/SelectBox';
import Location from '../../Location/Location';
import NameSearchModal from '../NameSearch/NameSearchModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_ArresteeName_Data, get_DLStateDrpData } from '../../../redux/actions/DropDownsData';
import { Carousel } from 'react-responsive-carousel';
import defualtImage from '../../../img/uploadImage.png';
import MasterGeneral from './MasterNameSubTab/MasterGeneral';
import MasterAppearance from './MasterNameSubTab/MasterAppearance';
import MasterAliases from './MasterNameSubTab/MasterAliases';
import MasterSmt from './MasterNameSubTab/MasterSmt';
import MasterIdentificationNumber from './MasterNameSubTab/MasterIdentificationNumber';
import MasterContactDetails from './MasterNameSubTab/MasterContactDetails';
import MasterAddress from './MasterNameSubTab/MasterAddress';
import MasterDocument from './MasterNameSubTab/MasterDocument';
import MasterChangesModal from './MasterChangeModel';


const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <p className='ml-2 d-inline'>{props.label}</p>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const MasterNameModel = (props) => {

    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const dlStateDrpData = useSelector((state) => state.DropDown.dlStateDrpData);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);

    const { setArrestID, setOwnerOfID, ownerOfID, possenSinglData, value, setValue, nameModalStatus, setNameModalStatus, loginAgencyID, loginPinID, type, setPossessionID, possessionID, setPossenSinglData, GetSingleDataPassion } = props

    const { nameSearchStatus, get_vehicle_Count, setNameSearchStatus, get_Arrestee_Drp_Data, setChangesStatus, get_Name_Count, changesStatus, tabCount, NameTabCount, MasterNameTabCount, get_MasterName_Count } = useContext(AgencyContext);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    let openPage = query?.get('page');
    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    let MstPage = query?.get('page');

    const crossButtonRef = useRef(null);
    const closeButtonRef = useRef(null);
    const saveButtonRef = useRef(null);
    const saveAndContRef = useRef(null);

    const [clickedRow, setClickedRow] = useState(null);
    const [editval, setEditval] = useState([]);
    const [dobDate, setDobDate] = useState();
    const [yearsVal, setYearsVal] = useState();
    const [ethinicityDrpData, setEthinicityDrpData] = useState([]);
    const [ageUnitDrpData, setAgeUnitDrpData] = useState([]);
    const [nameTypeIdDrp, setNameTypeIdDrp] = useState([]);
    const [suffixIdDrp, setSuffixIdDrp] = useState([]);
    const [verifyIdDrp, setVerifyIdDrp] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [phoneTypeIdDrp, setPhoneTypeIdDrp] = useState([]);
    const [reasonIdDrp, setReasonIdDrp] = useState([]);
    const [certifiedByIdDrp, setCertifiedByIdDrp] = useState([]);
    const [nameTypeCode, setNameTypeCode] = useState();
    const [phoneTypeCode, setPhoneTypeCode] = useState('');
    const [isAdult, setIsAdult] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [addVerifySingleData, setAddVerifySingleData] = useState([]);
    const [locationStatus, setLocationStatus] = useState(false);
    const [masterNameID, setMasterNameID] = useState();
    const [nameID, setNameID] = useState();
    const [juvinile, setJuvinile] = useState();
    const [masterNameSearchModal, setMasterNameSearchModal] = useState(true);
    const [nameSearchValue, setNameSearchValue] = useState([]);
    const [updateStatus, setUpdateStatus] = useState([]);
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [nameShowPage, setNameShowPage] = useState('home');
    const [saveContinueStatus, setsaveContinueStatus] = useState(false);
    const [mstPossessionID, setMstPossessionID] = useState('');
    const [globalname, setglobalname] = useState('');
    const [globalnameto, setglobalnameto] = useState('');
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    const [multiSelected, setMultiSelected] = useState({
        optionSelected: null
    });

    const [masterNameValues, setmasterNameValues] = useState({
        'NameIDNumber': 'Auto Generated', 'Address': '', 'Contact': '', 'NameTypeID': '', 'BusinessTypeID': '', 'SuffixID': '',
        'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': '', 'CertifiedByID': '', 'EthnicityID': '',
        'AgeUnitID': '', 'IsJuvenile': false, 'IsCurrentPh': true, 'IsVerify': true, 'IsUnListedPhNo': '', 'HeightFrom': '', 'HeightTo': '',
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'DateOfBirth': '', 'CertifiedDtTm': '',
        'AgeFrom': '', 'OwnerNameID': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'NameID': '', 'ArrestID': "", 'WarrantID': "",
        'AgeTo': '', 'Years': '', 'EventType': 'I', 'ModifiedByUserFK': '', 'MasterNameID': '', 'TicketID': "", 'checkVictem': 0, 'checkOffender': 0,
        'checkArrest': 0, 'CreatedByUserFK': '', 'AgencyID': '', 'IncidentID': '', 'NameLocationID': '', 'DLStateID': '', 'IsMaster': false, 'DLNumber': '',
    })

    const [errors, setErrors] = useState({
        'NameTypeIDError': '', 'LastNameError': '', 'FirstNameError': '', 'MiddleNameError': '', 'NameReasonCodeIDError': '', 'CertifiedByIDError': '', 'ContactError': 'true', 'WeightError': 'true',
        'AgeError': 'true', 'DateOfBirthError': '', 'RaceIDError': '', 'SexIDError': '', 'AddressError': 'true', 'DLNumberError': '', 'HeightError': 'true', 'SsnNoError': '',
    })

    useEffect(() => {
        if ((IncID && !possessionID) || (IncID && !ownerOfID)) {
            setmasterNameValues({
                ...masterNameValues,
                'NameIDNumber': 'Auto Generated', 'Address': '', 'Contact': '', 'BusinessTypeID': '', 'SuffixID': '', 'DLStateID': '',
                'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': '', 'CertifiedByID': '', 'EthnicityID': '',
                'AgeUnitID': '', 'IsJuvenile': false, 'IsCurrentPh': true, 'IsVerify': true, 'IsUnListedPhNo': '', 'HeightFrom': '', 'HeightTo': '',
                'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'DateOfBirth': '', 'CertifiedDtTm': '',
                'AgeFrom': '', 'OwnerNameID': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'NameID': '', 'ArrestID': "", 'WarrantID': "",
                'AgeTo': '', 'Years': '', 'EventType': 'I', 'ModifiedByUserFK': '', 'MasterNameID': '', 'TicketID': "", 'checkVictem': 0, 'checkOffender': 0,
                'checkArrest': 0, 'CreatedByUserFK': '', 'AgencyID': '', 'IncidentID': IncID, 'NameLocationID': '',
            });
            setMultiSelected({ optionSelected: [], });
            // condition for unwanted api calling
            get_MasterName_Count(possessionID, 0, MstPage === "MST-Name-Dash" ? true : false);
        }
        if (IncID) {
            setMainIncidentID(IncID);
            get_Arrestee_Drp_Data('', 0, IncID);
            dispatch(get_ArresteeName_Data('', 0, IncID));

            // condition for unwanted api calling
            nameModalStatus && get_vehicle_Count(possessionID)
        }
    }, [IncID, nameModalStatus]);

    useEffect(() => {
        if (loginAgencyID && nameModalStatus) {
            if (nameTypeIdDrp.length === 0) { GetNameTypeIdDrp(loginAgencyID); }
            if (suffixIdDrp?.length === 0) { GetSuffixIDDrp(loginAgencyID); }
            if (ageUnitDrpData?.length === 0) { getAgeUnitDrp(loginAgencyID); }
            if (sexIdDrp?.length === 0) { GetSexIDDrp(loginAgencyID); }
            if (raceIdDrp?.length === 0) { GetRaceIdDrp(loginAgencyID); }
            if (ethinicityDrpData?.length === 0) { getEthinicityDrp(loginAgencyID); }
            if (phoneTypeIdDrp?.length === 0) { GetPhoneTypeIDDrp(loginAgencyID, '1', '1'); }
            if (verifyIdDrp?.length === 0) { GetVerifyIDDrp(loginAgencyID); }
            if (certifiedByIdDrp?.length === 0) { getcertifiedByIdDrp(loginAgencyID); }
        }
        if (dlStateDrpData?.length === 0) { dispatch(get_DLStateDrpData()) }
    }, [loginAgencyID, nameModalStatus])

    useEffect(() => {
        if (loginAgencyID && masterNameValues.NameTypeID) {
            GetReasonIdDrp(loginAgencyID, masterNameValues.NameTypeID, type);
        }
    }, [masterNameValues.NameTypeID, type, nameModalStatus])

    useEffect(() => {
        if (possenSinglData?.length > 0) {
            setmasterNameValues({
                ...masterNameValues,
                'MasterNameID': possenSinglData[0]?.MasterNameID, 'NameID': possenSinglData[0]?.NameID,
                // 'AgeTo': possenSinglData[0]?.AgeTo ? possenSinglData[0]?.AgeTo : '', 
                'AgeFrom': possenSinglData[0]?.AgeFrom === null ? null : possenSinglData[0]?.AgeFrom ?? '0',
                'AgeTo': possenSinglData[0]?.AgeTo ? possenSinglData[0]?.AgeTo : '',
                'SexID': possenSinglData[0]?.SexID,
                'NameIDNumber': possenSinglData[0]?.NameIDNumber ? possenSinglData[0]?.NameIDNumber : 'Auto Generated',
                'checkVictem': possenSinglData[0]?.NewVictimID ? possenSinglData[0]?.NewVictimID[0]?.NewVictimID : "",
                'checkOffender': possenSinglData[0]?.NewOffenderID ? possenSinglData[0]?.NewOffenderID[0]?.NewOffenderID : "",
                'VerifyID': possenSinglData[0]?.VerifyID, 'AgeUnitID': possenSinglData[0]?.AgeUnitID,
                'checkArrest': possenSinglData[0]?.ArrestID ? possenSinglData[0]?.ArrestID[0]?.ArrestID : "", 'SuffixID': possenSinglData[0]?.SuffixID,
                'NameTypeID': possenSinglData[0]?.NameTypeID, 'BusinessTypeID': possenSinglData[0]?.BusinessTypeID,
                'RaceID': possenSinglData[0]?.RaceID, 'PhoneTypeID': possenSinglData[0]?.PhoneTypeID, 'EthnicityID': possenSinglData[0]?.EthnicityID,
                'NameReasonCodeID': possenSinglData[0]?.ReasonCode ? changeArray(possenSinglData[0]?.ReasonCode, 'NameReasonCodeID') : '',
                'CertifiedByID': possenSinglData[0]?.CertifiedByID, 'IsJuvenile': possenSinglData[0]?.IsJuvenile,
                'OwnerNameID': possenSinglData[0]?.OwnerNameID, 'DLStateID': possenSinglData[0]?.DLStateID,
                'IsVerify': possenSinglData[0]?.IsVerify, 'IsUnListedPhNo': possenSinglData[0]?.IsUnListedPhNo,
                'OwnerFaxNumber': possenSinglData[0]?.OwnerFaxNumber, 'OwnerPhoneNumber': possenSinglData[0]?.OwnerPhoneNumber,
                'LastName': possenSinglData[0]?.LastName, 'FirstName': possenSinglData[0]?.FirstName, 'MiddleName': possenSinglData[0]?.MiddleName,
                'SSN': possenSinglData[0]?.SSN, 'WeightFrom': possenSinglData[0]?.WeightFrom, 'WeightTo': possenSinglData[0]?.WeightTo,
                'HeightFrom': possenSinglData[0]?.HeightFrom, 'HeightTo': possenSinglData[0]?.HeightTo, 'Address': possenSinglData[0]?.Address,
                'Contact': possenSinglData[0]?.Contact,
                'DateOfBirth': possenSinglData[0]?.DateOfBirth ? getShowingWithOutTime(possenSinglData[0]?.DateOfBirth) : '',
                'CertifiedDtTm': possenSinglData[0]?.CertifiedDtTm ? getShowingDateText(possenSinglData[0]?.CertifiedDtTm) : null,
                'Years': possenSinglData[0]?.Years, 'NameLocationID': possenSinglData[0]?.NameLocationID,
                'ModifiedByUserFK': loginPinID, 'AgencyID': loginAgencyID, 'DLNumber': possenSinglData[0]?.DLNumber?.replace(/-/g, ''),
            });
            setIsAdult(possenSinglData[0]?.ReasonCode?.some(function (item) { return item.ReasonCode_Description === "Adult Arrest" }));
            setNameTypeCode(possenSinglData[0]?.NameTypeCode); setMstPossessionID(possenSinglData[0]?.MasterNameID);
            if (type === "MissingMod" && possenSinglData[0]?.ReasonCode && nameModalStatus === true) {
                let newReasonCodeData = multiSelected?.optionSelected.concat(fourColArray(
                    possenSinglData[0]?.ReasonCode, 'NameReasonCodeID', 'ReasonCode_Description', 'IsVictimName', 'IsOffenderName'
                ))
                setMultiSelected({ optionSelected: newReasonCodeData });
            } else {
                setMultiSelected({
                    optionSelected: possenSinglData[0]?.ReasonCode ? fourColArray(possenSinglData[0]?.ReasonCode, 'NameReasonCodeID', 'ReasonCode_Description', 'IsVictimName', 'IsOffenderName'
                    ) : '',
                });
            }
            setPhoneTypeCode(possenSinglData[0]?.PhoneTypeID);
        } else {
            setmasterNameValues({
                ...masterNameValues,
                'NameIDNumber': 'Auto Generated', 'Address': '', 'Contact': '', 'BusinessTypeID': '', 'SuffixID': '', 'DLStateID': '',
                'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'CertifiedByID': '', 'EthnicityID': '',
                'AgeUnitID': '', 'IsJuvenile': false, 'IsCurrentPh': true, 'IsVerify': true, 'IsUnListedPhNo': '', 'HeightFrom': '', 'HeightTo': '',
                'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'DateOfBirth': '', 'CertifiedDtTm': '',
                'AgeFrom': '', 'OwnerNameID': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'NameID': '', 'ArrestID': "", 'WarrantID': "",
                'AgeTo': '', 'Years': '', 'EventType': 'I', 'ModifiedByUserFK': '', 'MasterNameID': '', 'TicketID': "", 'checkVictem': 0, 'checkOffender': 0,
                'checkArrest': 0, 'CreatedByUserFK': '', 'AgencyID': '', 'IncidentID': IncID, 'NameLocationID': '', 'DLNumber': '',
            });
            setPhoneTypeCode('');
            const id = nameTypeIdDrp?.filter((val) => { if (val.id === "I") return val })
            if (id.length > 0) {
                setmasterNameValues(prevValues => { return { ...prevValues, ['NameTypeID']: id[0].value } })
                setNameTypeCode(id[0].NameTypeCode);
            }
            // setMultiSelected({ optionSelected: [], });
        }
    }, [possenSinglData, nameModalStatus])

    const handleKeyDown = (e) => {
        const charCode = e.keyCode || e.which;
        const charStr = String.fromCharCode(charCode);
        const controlKeys = [8, 9, 13, 27, 37, 38, 39, 40, 46];
        const numpadKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
        const numpadSpecialKeys = [106, 107, 109, 110, 111];
        if (!charStr.match(/^[a-zA-Z]+$/) && !controlKeys.includes(charCode)) {
            e.preventDefault();
        }
        if ((charCode >= 48 && charCode <= 57) || numpadKeys.includes(charCode) || numpadSpecialKeys.includes(charCode)) {
            e.preventDefault();
        }
    };

    const check_Validation_Error = () => {
        const { LastName, FirstName, MiddleName, NameTypeID, NameReasonCodeID, SSN, DLStateID, DLNumber, Contact, HeightFrom, HeightTo, WeightFrom, WeightTo, AgeFrom, AgeTo, SexID, RaceID, DateOfBirth, IsUnknown } = masterNameValues;
        if (isAdult) {
            const SexIDError = RequiredFieldIncident(masterNameValues?.SexID);
            const RaceIDError = RequiredFieldIncident(masterNameValues?.RaceID);
            const DateOfBirthError = isAdult && masterNameValues?.IsUnknown ? 'true' : RequiredField(masterNameValues.DateOfBirth);
            const SSNError = type === "ArrestMod" || masterNameValues?.SSN ? SSN_Field(masterNameValues?.SSN) : 'true';

            const NameTypeIDErr = RequiredFieldIncident(masterNameValues?.NameTypeID);
            const LastNameErr = NameValidationCharacter(LastName, 'LastName', FirstName, MiddleName, LastName);
            const FirstNameErr = NameValidationCharacter(FirstName, 'FirstName', FirstName, MiddleName, LastName);
            const MiddleNameErr = NameValidationCharacter(MiddleName, 'MiddleName', FirstName, MiddleName, LastName);
            const NameReasonCodeIDErr = RequiredFieldIncident(masterNameValues.NameReasonCodeID);
            const DLNumberErr = masterNameValues?.DLStateID ? RequiredFieldIncident(masterNameValues.DLNumber) : 'true';

            setErrors(pre => {
                return {
                    ...pre,
                    ['SexIDError']: SexIDError || pre['SexIDError'],
                    ['RaceIDError']: RaceIDError || pre['RaceIDError'],
                    ['SsnNoError']: SSNError || pre['SsnNoError'],
                    ['DateOfBirthError']: DateOfBirthError || pre['DateOfBirthError'],

                    ['NameTypeIDError']: NameTypeIDErr || pre['NameTypeIDError'],
                    ['LastNameError']: LastNameErr || pre['LastNameError'],
                    ['FirstNameError']: FirstNameErr || pre['FirstNameError'],
                    ['MiddleNameError']: MiddleNameErr || pre['MiddleNameError'],
                    ['NameReasonCodeIDError']: NameReasonCodeIDErr || pre['NameReasonCodeIDError'],
                    ['DLNumberError']: DLNumberErr || pre['DLNumberError'],
                }
            })

            if (Heights(masterNameValues.HeightFrom, masterNameValues.HeightTo, 'Height') === 'true') {
                setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'true' } })
            } else {
                setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'error' } })
            }
            if (Comparision(masterNameValues.WeightFrom, masterNameValues.WeightTo, 'Weight') === 'true') {
                setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'true' } })
            } else {
                setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'error' } })
            }

        } else {

            const SexIDError = type === "ArrestMod" || type === "MissingMod" ? RequiredFieldIncident(masterNameValues?.SexID) : 'true';
            const RaceIDError = type === "ArrestMod" || type === "MissingMod" ? RequiredFieldIncident(masterNameValues?.RaceID) : 'true';
            const SSNError = type === "ArrestMod" || masterNameValues?.SSN ? SSN_Field(masterNameValues?.SSN) : 'true';
            const DateOfBirthError = type === "ArrestMod" || type === "MissingMod" ? RequiredField(masterNameValues.DateOfBirth) : 'true'
            // const DateOfBirthError = type != "ArrestMod" || type != "MissingMod" || masterNameValues?.IsUnknown ? 'true' : RequiredField(masterNameValues.DateOfBirth);

            const NameTypeIDErr = RequiredFieldIncident(masterNameValues?.NameTypeID);
            const LastNameErr = NameValidationCharacter(LastName, 'LastName', FirstName, MiddleName, LastName);
            const FirstNameErr = NameValidationCharacter(FirstName, 'FirstName', FirstName, MiddleName, LastName);
            const MiddleNameErr = NameValidationCharacter(MiddleName, 'MiddleName', FirstName, MiddleName, LastName);
            const NameReasonCodeIDErr = RequiredFieldIncident(masterNameValues.NameReasonCodeID);
            const DLNumberErr = masterNameValues?.DLStateID ? RequiredFieldIncident(masterNameValues.DLNumber) : 'true';
            const ContactErr = phoneTypeCode ? phoneTypeCode === 'E' ? Email_Field(masterNameValues.Contact) : PhoneFieldNotReq(masterNameValues.Contact) : 'true';
            setErrors(pre => {
                return {
                    ...pre,
                    ['SexIDError']: SexIDError || pre['SexIDError'],
                    ['RaceIDError']: RaceIDError || pre['RaceIDError'],
                    ['SsnNoError']: SSNError || pre['SsnNoError'],
                    ['DateOfBirthError']: DateOfBirthError || pre['DateOfBirthError'],
                    ['ContactError']: ContactErr || pre['ContactError'],
                    ['NameTypeIDError']: NameTypeIDErr || pre['NameTypeIDError'],
                    ['LastNameError']: LastNameErr || pre['LastNameError'],
                    ['FirstNameError']: FirstNameErr || pre['FirstNameError'],
                    ['MiddleNameError']: MiddleNameErr || pre['MiddleNameError'],
                    ['NameReasonCodeIDError']: NameReasonCodeIDErr || pre['NameReasonCodeIDError'],
                    ['DLNumberError']: DLNumberErr || pre['DLNumberError'],
                }
            })
            if (phoneTypeCode === 'E') {
                Email_Field(masterNameValues.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: Email_Field(masterNameValues.Contact) } })
            } else if (phoneTypeCode) {
                PhoneFieldNotReq(masterNameValues.Contact) && setErrors(prevValues => { return { ...prevValues, ['ContactError']: PhoneFieldNotReq(masterNameValues.Contact) } })
            }
            if (Heights(masterNameValues.HeightFrom, masterNameValues.HeightTo, 'Height') === 'true') {
                setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'true' } })
            } else {
                setErrors(prevValues => { return { ...prevValues, ['HeightError']: 'error' } })
            }
            if (Comparision(masterNameValues.WeightFrom, masterNameValues.WeightTo, 'Weight') === 'true') {
                setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'true' } })
            } else {
                setErrors(prevValues => { return { ...prevValues, ['WeightError']: 'error' } })
            }
        }
    }

    const { NameTypeIDError, LastNameError, ContactError, FirstNameError, MiddleNameError, NameReasonCodeIDError, SexIDError, RaceIDError, DateOfBirthError, DLNumberError, SsnNoError, HeightError, WeightError } = errors

    useEffect(() => {
        if (isAdult) {
            if (NameReasonCodeIDError === 'true' && ContactError === 'true' && HeightError === 'true' && LastNameError === 'true' && FirstNameError === 'true' && MiddleNameError === 'true' && NameTypeIDError === 'true' && SexIDError === 'true' && RaceIDError === 'true' && WeightError === 'true' && DateOfBirthError === 'true' && DLNumberError === 'true' && SsnNoError === 'true') {
                if (type === "VehicleOwner") {
                    if (ownerOfID && possenSinglData.length > 0) {
                        Update_Name(); return;
                    } else {
                        insertMasterName(); return;
                    }
                } else {
                    if (possessionID && possenSinglData.length > 0) {
                        Update_Name(); return;
                    } else {
                        insertMasterName(); return;
                    }
                }
            }
        } else if (NameReasonCodeIDError === 'true' && HeightError === 'true' && ContactError === 'true' && LastNameError === 'true' && FirstNameError === 'true' && MiddleNameError === 'true' && NameTypeIDError === 'true' && SexIDError === 'true' && RaceIDError === 'true' && DateOfBirthError === 'true' && WeightError === 'true' && DLNumberError === 'true' && SsnNoError === 'true') {
            if (type === "VehicleOwner") {
                if (ownerOfID && possenSinglData.length > 0) {
                    Update_Name(); return;
                } else {
                    insertMasterName(); return;
                }
            } else {
                if (possessionID && possenSinglData.length > 0) {
                    Update_Name(); return;
                } else {
                    insertMasterName(); return;
                }
            }
        }
    }, [LastNameError, FirstNameError, MiddleNameError, ContactError, WeightError, NameTypeIDError, NameReasonCodeIDError, DateOfBirthError, RaceIDError, SexIDError, HeightError, DLNumberError, SsnNoError])

    const insertMasterName = () => {
        const AgencyID = loginAgencyID;
        const CreatedByUserFK = loginPinID;
        const { NameIDNumber, Address, Contact, NameTypeID, BusinessTypeID, SuffixID,
            VerifyID, SexID, RaceID, PhoneTypeID, NameReasonCodeID, CertifiedByID, EthnicityID,
            AgeUnitID, IsJuvenile, IsCurrentPh, IsVerify, IsUnListedPhNo, HeightFrom, HeightTo,
            LastName, FirstName, MiddleName, SSN, WeightFrom, WeightTo, DateOfBirth, CertifiedDtTm,
            AgeFrom, OwnerNameID, OwnerPhoneNumber, OwnerFaxNumber, NameID, ArrestID, WarrantID,
            AgeTo, Years, EventType, ModifiedByUserFK, MasterNameID, TicketID, checkVictem, checkOffender,
            checkArrest, IncidentID, NameLocationID, DLStateID, IsMaster, DLNumber, } = masterNameValues;

        const values = {
            NameIDNumber, Address, Contact, NameTypeID, BusinessTypeID, SuffixID,
            VerifyID, SexID, RaceID, PhoneTypeID, NameReasonCodeID, CertifiedByID, EthnicityID,
            AgeUnitID, IsJuvenile, IsCurrentPh, IsVerify, IsUnListedPhNo, HeightFrom, HeightTo,
            LastName, FirstName, MiddleName, SSN, WeightFrom, WeightTo, DateOfBirth, CertifiedDtTm,
            AgeFrom, OwnerNameID, OwnerPhoneNumber, OwnerFaxNumber, NameID, ArrestID, WarrantID,
            AgeTo, Years, EventType, ModifiedByUserFK, MasterNameID, TicketID, checkVictem, checkOffender,
            checkArrest, CreatedByUserFK, AgencyID, IncidentID, NameLocationID, DLStateID, IsMaster, DLNumber,
        };
        fetchPostData("MasterName/GetData_EventNameExists", {
            "MasterNameID": MasterNameID, "SSN": SSN, "IncidentID": mainIncidentID, "NameID": NameID,
            // "NameID" : NameID,"SSN": SSN,"MasterNameID": MasterNameID
        }).then((data) => {
            setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '', });
            if (data) {

                if (data[0]?.Total === 0) {
                    AddDeleteUpadate('MasterName/Insert_MasterName', values).then((res) => {
                        if (res.success) {
                            if (type === "Property") {
                                setValue({ ...value, ['PossessionOfID']: res?.NameID, ['Name']: res.Name }); setPossessionID(parseInt(res?.NameID)); setMstPossessionID(res?.MasterNameID); GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "Pro-Owner") {
                                setValue({ ...value, ['OwnerID']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "NameBusiness") {
                                setValue({ ...value, ['OwnerNameID']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "ArresteeID") {
                                setValue({ ...value, ['ArresteeID']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "VehicleOwner") {
                                setValue({ ...value, ['OwnerID']: res?.NameID, ['Name']: res.Name }); setOwnerOfID(res?.NameID); setMstPossessionID(res?.MasterNameID); GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            } else if (type === "VehicleName") {
                                setValue({ ...value, ['InProfessionOf']: res?.NameID, ['Name']: res.Name }); setOwnerOfID(res?.NameID); setMstPossessionID(res?.MasterNameID); GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "LastSeenInfo") {
                                setValue({ ...value, ['MissingPersonID']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "MissingNotify") {
                                setValue({ ...value, ['NameID']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "MissingMod") {
                                setValue({ ...value, ['MissingPersonNameID']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else if (type === "ArrestMod") {
                                setValue({ ...value, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            else {
                                setValue({ ...value, ['inProfessionOf']: res?.NameID, ['Name']: res.Name }); setPossessionID(res?.NameID); setMstPossessionID(res?.MasterNameID);
                                GetSingleDataPassion(res?.NameID, res?.MasterNameID)
                            }
                            toastifySuccess(res.Message);
                            setStatesChangeStatus(false);
                            if (!saveContinueStatus) {
                                Reset(); setNameModalStatus(false)
                            }
                            setErrors({ ...errors, ['NameTypeIDError']: '', });
                            setChangesStatus(false)
                            get_MasterName_Count(res?.NameID, 0, MstPage === "MST-Name-Dash" ? true : false);
                        }

                    })
                } else {
                    toastifyError('SSN Already Exists');
                }
            }
        })

    }

    const Update_Name = () => {
        const AgencyID = loginAgencyID;
        const { NameIDNumber, Address, Contact, NameTypeID, BusinessTypeID, SuffixID,
            VerifyID, SexID, RaceID, PhoneTypeID, NameReasonCodeID, CertifiedByID, EthnicityID,
            AgeUnitID, IsJuvenile, IsCurrentPh, IsVerify, IsUnListedPhNo, HeightFrom, HeightTo,
            LastName, FirstName, MiddleName, SSN, WeightFrom, WeightTo, DateOfBirth, CertifiedDtTm,
            AgeFrom, OwnerNameID, OwnerPhoneNumber, OwnerFaxNumber, NameID, ArrestID, WarrantID,
            AgeTo, Years, EventType, ModifiedByUserFK, MasterNameID, TicketID, checkVictem, checkOffender,
            checkArrest, IncidentID, NameLocationID, DLStateID, IsMaster, DLNumber, } = masterNameValues;
        const val = {
            NameIDNumber, Address, Contact, NameTypeID, BusinessTypeID, SuffixID,
            VerifyID, SexID, RaceID, PhoneTypeID, NameReasonCodeID, CertifiedByID, EthnicityID,
            AgeUnitID, IsJuvenile, IsCurrentPh, IsVerify, IsUnListedPhNo, HeightFrom, HeightTo,
            LastName, FirstName, MiddleName, SSN, WeightFrom, WeightTo, DateOfBirth, CertifiedDtTm,
            AgeFrom, OwnerNameID, OwnerPhoneNumber, OwnerFaxNumber, NameID, ArrestID, WarrantID,
            AgeTo, Years, EventType, ModifiedByUserFK, MasterNameID, TicketID, checkVictem, checkOffender,
            checkArrest, AgencyID, IncidentID, NameLocationID, DLStateID, IsMaster, DLNumber,
        };
        fetchPostData("MasterName/GetData_EventNameExists", {
            // "MasterNameID": MasterNameID, "SSN": SSN, "IncidentID": mainIncidentID
            "MasterNameID": MasterNameID, "SSN": SSN, "IncidentID": mainIncidentID, "NameID": NameID,
        }).then((data) => {
            setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '', });
            if (data) {

                if (data[0]?.Total === 0) {
                    AddDeleteUpadate('MasterName/Update_MasterName', val).then((res) => {

                        if (res.success) {
                            const parsedData = JSON.parse(res.data);
                            const message = parsedData.Table[0].Message;
                            toastifySuccess(message);
                            setErrors({ ...errors, ['NameTypeIDError']: '', ['LastNameError']: '', ['FirstNameError']: '', });
                            setChangesStatus(false)
                            setStatesChangeStatus(false);
                            get_MasterName_Count(NameID, MasterNameID, MstPage === "MST-Name-Dash" ? true : false);
                        }
                    })
                } else {
                    toastifyError('SSN Already Exists');
                }
            }
        })

    }

    const GetNameTypeIdDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('NameType/GetDataDropDown_NameType', val).then((data) => {
            if (data) {
                const id = data?.filter((val) => { if (val.NameTypeCode === "I") return val })
                if (id.length > 0 && editval.length === 0) {
                    setmasterNameValues(prevValues => { return { ...prevValues, ['NameTypeID']: id[0].NameTypeID } })
                    setNameTypeCode(id[0].NameTypeCode);
                }
                setNameTypeIdDrp(threeColArray(data, 'NameTypeID', 'Description', 'NameTypeCode'))
            } else {
                setNameTypeIdDrp([]);
            }
        })
    };

    const GetReasonIdDrp = (loginAgencyID, id, type) => {

        const val = { AgencyID: loginAgencyID, CategoryID: id, }

        fetchPostData('NameReasonCode/GetDataDropDown_NameReasonCode', val).then((data) => {
            if (data) {
                setReasonIdDrp(Comman_changeArrayFormat(data, 'NameReasonCodeID', 'Description'))
                if (openPage === 'Victim') {
                    const id = data?.filter((val) => { if (val?.ReasonCode === "VIC") return val });
                    if (id?.length > 0) {
                        setMultiSelected({
                            optionSelected: id ? fourColArray(id, 'NameReasonCodeID', 'Description', 'IsVictimName', 'IsOffenderName') : '',
                        });
                        let finalValueList = id?.map((item) => item?.NameReasonCodeID);
                        setmasterNameValues({ ...masterNameValues, ['NameReasonCodeID']: finalValueList })
                    }
                } else if (openPage === 'Offender') {
                    const id = data?.filter((val) => { if (val?.ReasonCode === "OFF") return val });
                    if (id?.length > 0) {
                        setMultiSelected({
                            optionSelected: id ? fourColArray(id, 'NameReasonCodeID', 'Description', 'IsVictimName', 'IsOffenderName') : '',
                        });
                        let finalValueList = id?.map((item) => item?.NameReasonCodeID);
                        setmasterNameValues({ ...masterNameValues, ['NameReasonCodeID']: finalValueList })
                    }
                } else if (type === "NameBusiness" || type === "VehicleOwner") {
                    const id = data?.filter((val) => { if (val?.ReasonCode === "OWN") return val });
                    if (id?.length > 0) {
                        setMultiSelected({
                            optionSelected: id ? fourColArray(id, 'NameReasonCodeID', 'Description', 'IsVictimName', 'IsOffenderName') : '',
                        });
                        let finalValueList = id?.map((item) => item?.NameReasonCodeID);
                        setmasterNameValues({ ...masterNameValues, ['NameReasonCodeID']: finalValueList })
                    }
                }
                else if (type === "MissingMod") {
                    const id = data?.filter((val) => { if (val?.ReasonCode === "MIS") return val });
                    if (id?.length > 0) {
                        setMultiSelected({
                            optionSelected: id ? fourColArray(id, 'NameReasonCodeID', 'Description', 'IsVictimName', 'IsOffenderName') : '',
                        });
                        let finalValueList = id?.map((item) => item?.NameReasonCodeID);
                        setmasterNameValues({ ...masterNameValues, ['NameReasonCodeID']: finalValueList })
                    }
                }
            } else {
                setReasonIdDrp([]);
            }
        })
    }

    const GetSuffixIDDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('Suffix/GetDataDropDown_Suffix', val).then((data) => {
            if (data) {
                setSuffixIdDrp(Comman_changeArrayFormat(data, 'SuffixID', 'Description'))
            } else {
                setSuffixIdDrp([]);
            }
        })
    };

    const getAgeUnitDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID }
        fetchPostData('/AgeUnit/GetDataDropDown_AgeUnit', val).then((data) => {
            if (data) {
                setAgeUnitDrpData(threeColArray(data, 'AgeUnitID', 'Description', 'AgeUnitCode'));
            }
            else {
                setAgeUnitDrpData([])
            }
        })
    };

    const GetSexIDDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('DropDown/GetData_SexType', val).then((data) => {
            if (data) {
                setSexIdDrp(Comman_changeArrayFormat(data, 'SexCodeID', 'Description'))
            } else {
                setSexIdDrp([]);
            }
        })
    }

    const GetRaceIdDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('DropDown/GetData_RaceType', val).then((data) => {
            if (data) {
                setRaceIdDrp(Comman_changeArrayFormat(data, 'RaceTypeID', 'Description'))
            } else {
                setRaceIdDrp([]);
            }
        })
    }

    const getEthinicityDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('/DropDown/GetDataDropDown_Ethnicity', val).then((data) => {
            if (data) {
                setEthinicityDrpData(Comman_changeArrayFormat(data, 'EthnicityID', 'Description'));
            }
            else {
                setEthinicityDrpData([])
            }
        })
    };

    const GetPhoneTypeIDDrp = (loginAgencyID, IsEMail, IsPhone) => {
        const val = { AgencyID: loginAgencyID, IsEMail: IsEMail, IsPhone: IsPhone, }
        fetchPostData('ContactPhoneType/GetDataDropDown_ContactPhoneType', val).then((data) => {
            if (data) {
                setPhoneTypeIdDrp(threeColArray(data, 'ContactPhoneTypeID', 'Description', 'ContactPhoneTypeCode'))
            } else {
                setPhoneTypeIdDrp([]);
            }
        })
    }

    const GetVerifyIDDrp = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('Verify/GetDataDropDown_Verify', val).then((data) => {
            if (data) {
                setVerifyIdDrp(Comman_changeArrayFormat(data, 'VerifyID', 'Description'))
            } else {
                setVerifyIdDrp([]);
            }
        })
    };

    const getcertifiedByIdDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setCertifiedByIdDrp(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            }
            else {
                setCertifiedByIdDrp([])
            }
        })
    };

    const ChangeNameType = (e, name) => {
        if (e) {
            setChangesStatus(true)
            setStatesChangeStatus(true)
            if (name === 'NameTypeID') {
                setmasterNameValues({
                    ...masterNameValues,
                    [name]: e.value,
                    'NameIDNumber': '', 'BusinessTypeID': '', 'SuffixID': '', 'DLStateID': '', 'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': [], 'CertifiedByID': '', 'AgeUnitID': '',
                    'IsVerify': true, 'IsUnListedPhNo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'HeightFrom': '', 'HeightTo': '', 'Address': '', 'Contact': '', 'DateOfBirth': '', 'CertifiedDtTm': null, 'AgeFrom': '', 'AgeTo': '', 'Years': '',
                });
                setLocationStatus(true);
                setMultiSelected({ optionSelected: [] }); setPhoneTypeCode(''); setNameTypeCode(e.id);
            } else {
                setmasterNameValues({
                    ...masterNameValues,
                    [name]: e.value
                })
            }
        } else {
            setStatesChangeStatus(true)
            setmasterNameValues({
                ...masterNameValues,
                [name]: null
            }); setNameTypeCode(''); setPhoneTypeCode('')
        }
    }

    const OnChangeSelectedReason = (data, name) => {
        setStatesChangeStatus(true)
        // <---------------Both function are to resturn true or false ----------------> 
        let adult = data.some(function (item) { return item.label === "Adult Arrest" });
        if (!adult) {
            setErrors({ ...errors, ['DateOfBirthError']: 'true', ['RaceIDError']: 'true', ['SexIDError']: 'true', ['NameTypeIDError']: '', });
        }
        setIsAdult(adult);
        const newArray = [...data]
        if (masterNameValues.checkOffender === 1 && masterNameValues.checkVictem === 1) {
            multiSelected.optionSelected?.map(val => {
                if (val.checkVictem) {
                    if (data.length > 0) {
                        return data?.filter(item => {
                            if (item.value === val.value) return newArray.push(val)
                            else newArray.push(val)
                        })
                    } else return newArray.push(val)
                }
                if (val.checkOff) {
                    if (data.length > 0) {
                        return data?.filter(item => {
                            if (item.value === val.value) return newArray.push(val)
                            else newArray.push(val)
                        })
                    } else return newArray.push(val)
                }
            })
            let finalValueList = newArray.filter((item, index) => newArray.indexOf(item) === index)?.map((item) => item.value);
            setmasterNameValues({
                ...masterNameValues,
                [name]: finalValueList
            });
            setMultiSelected({
                optionSelected: newArray.filter((item, index) => newArray.indexOf(item) === index)
            });
        }
        else if (masterNameValues.checkOffender === 1) {
            multiSelected.optionSelected?.map(val => {
                if (val.checkOff) {
                    if (data.length > 0) {
                        return data?.filter(item => {
                            if (item.value === val.value) return newArray.push(val)
                            else newArray.push(val)
                        })
                    } else return newArray.push(val)
                }
            })
            let finalValueList = newArray.filter((item, index) => newArray.indexOf(item) === index)?.map((item) => item.value);
            setmasterNameValues({
                ...masterNameValues,
                [name]: finalValueList
            })
            setMultiSelected({
                optionSelected: newArray.filter((item, index) => newArray.indexOf(item) === index)
            });
        }
        else if (masterNameValues.checkVictem === 1) {
            multiSelected.optionSelected?.map(val => {
                if (val.checkVictem) {
                    if (data.length > 0) {
                        return data?.filter(item => {
                            if (item.value === val.value) return newArray.push(val)
                            else newArray.push(val)
                        })
                    } else return newArray.push(val)
                }
            })
            let finalValueList = newArray.filter((item, index) => newArray.indexOf(item) === index)?.map((item) => item.value);
            setmasterNameValues({
                ...masterNameValues,
                [name]: finalValueList
            })
            setMultiSelected({
                optionSelected: newArray.filter((item, index) => newArray.indexOf(item) === index)
            });
        } else {
            let finalValueList = newArray?.map((item) => item.value);
            setmasterNameValues({
                ...masterNameValues,
                [name]: finalValueList
            })
            setMultiSelected({
                optionSelected: newArray
            });
        }
    };

    const handleDOBChange = (date, e) => {
        setStatesChangeStatus(true)
        if (date) {
            setChangesStatus(true)
            setDobDate(date);
            const res = getShowingWithOutTime(date).split("/")
            let age = calculateAge(`${res[0]} ${res[1]} ${res[2]}`);
            const id = ageUnitDrpData?.filter((val) => { if (val.id === "Y") return val })
            if (id.length > 0) {
                setmasterNameValues({
                    ...masterNameValues,
                    ['AgeFrom']: age, ['AgeTo']: '', ['Years']: age, ['DateOfBirth']: date ? getShowingWithOutTime(date) : null, ['AgeUnitID']: id[0].value,
                });
            }
        } else if (date === null) {
            setDobDate(''); setmasterNameValues({ ...masterNameValues, ['AgeFrom']: '', ['AgeTo']: '', ['DateOfBirth']: null, ['AgeUnitID']: null, });
            calculateAge(null)
        } else {
            setDobDate(''); setmasterNameValues({ ...masterNameValues, ['AgeFrom']: null, ['AgeTo']: '', ['DateOfBirth']: null, ['AgeUnitID']: null, });
            calculateAge(null)
        }
    };

    function calculateAge(birthday) {
        const today = new Date(incReportedDate);
        const birthDate = new Date(birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    }

    useEffect(() => {
        if (yearsVal < 18 || parseInt(masterNameValues.AgeFrom) < 18) {
            setJuvinile(true)
            setmasterNameValues({ ...masterNameValues, ['IsJuvenile']: true })
        } else {
            setJuvinile(false); setmasterNameValues({ ...masterNameValues, ['IsJuvenile']: false })
        }
        if (value.DateOfBirth) {
            const id = ageUnitDrpData?.filter((val) => { if (val.id === "Y") return val })
            if (id.length > 0) { setmasterNameValues(prevValues => { return { ...prevValues, ['AgeUnitID']: id[0].value } }) }
        }
    }, [masterNameValues.DateOfBirth, masterNameValues.AgeFrom]);

    const HandleChange = (e) => {
        setStatesChangeStatus(true)
        if (e.target.name === 'IsVerify' || e.target.name === 'IsUnListedPhNo' || e.target.name === 'IsUnknown') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            if (e.target.name === 'IsVerify') {
                if (e.target.checked && addVerifySingleData.length > 0) {
                    setModalStatus(false);
                    setLocationStatus(true); setAddVerifySingleData([]);
                    setmasterNameValues(pre => { return { ...pre, ['Address']: '', [e.target.name]: e.target.checked, } });
                } else {
                    setmasterNameValues(pre => { return { ...pre, [e.target.name]: e.target.checked, } });
                    setModalStatus(true);
                    setLocationStatus(false);
                }
            } else {
                setmasterNameValues({ ...masterNameValues, [e.target.name]: e.target.checked });
            }
        } else if (e.target.name === 'Contact') {
            setStatesChangeStatus(true)
            if (phoneTypeCode === 'E') {
                setChangesStatus(true)
                setmasterNameValues({ ...masterNameValues, [e.target.name]: e.target.value });
            } else {
                let ele = e.target.value.replace(/\D/g, '');
                if (ele.length === 10) {
                    setmasterNameValues(pre => { return { ...pre, ['IsUnListedPhNo']: 'true', } });
                    const cleaned = ('' + ele).replace(/\D/g, '');
                    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (match) {
                        setChangesStatus(true)
                        setmasterNameValues({
                            ...masterNameValues,
                            [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                        })
                    }
                } else {
                    ele = e.target.value.split('-').join('').replace(/\D/g, '');
                    setChangesStatus(true)
                    setmasterNameValues({
                        ...masterNameValues,
                        [e.target.name]: ele
                    })
                }
            }
        }
        else if (e.target.name === 'OwnerPhoneNumber') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length <= 10) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    setmasterNameValues({
                        ...masterNameValues,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3],
                    });
                } else {
                    setmasterNameValues({
                        ...masterNameValues,
                        [e.target.name]: ele,
                    });
                }
            }
        } else if (e.target.name === 'OwnerFaxNumber') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length <= 10) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    setmasterNameValues({
                        ...masterNameValues,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3],
                    });
                } else {
                    setmasterNameValues({
                        ...masterNameValues,
                        [e.target.name]: ele,
                    });
                }
            }
        } else if (e.target.name === 'SSN') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setmasterNameValues({
                        ...masterNameValues,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    });
                    getNameSearch(loginAgencyID, value?.NameTypeID, null, null, null, null, match[1] + '-' + match[2] + '-' + match[3])
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setmasterNameValues({
                    ...masterNameValues,
                    [e.target.name]: ele
                })
            }
        } else if (e.target.name === 'WeightTo' || e.target.name === 'WeightFrom') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let checkNumber = e.target.value.replace(/[^0-9]/g, "");
            setmasterNameValues({ ...masterNameValues, [e.target.name]: checkNumber })
        } else if (e.target.name === 'HeightFrom') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setmasterNameValues({
                ...masterNameValues,
                [e.target.name]: checkNumber
            })
        } else if (e.target.name === 'HeightTo') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setmasterNameValues({
                ...masterNameValues,
                [e.target.name]: checkNumber
            })
        } else if (e.target.name === 'AgeTo' || e.target.name === 'AgeFrom') {
            setStatesChangeStatus(true)
            setChangesStatus(true)
            let checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setDobDate('');
            setmasterNameValues({ ...masterNameValues, [e.target.name]: checkNumber, ['Years']: null, ['DateOfBirth']: null })
        } else { setmasterNameValues({ ...masterNameValues, [e.target.name]: e.target.value }) }
    };

    const LastFirstNameOnBlur = (e) => {
        if (e.target.name === 'LastName') {
            setStatesChangeStatus(true)
            if (masterNameValues?.LastName && masterNameValues?.FirstName) {
                getNameSearch(loginAgencyID, masterNameValues?.NameTypeID, masterNameValues?.LastName, masterNameValues?.FirstName, null, null)
            }
        } else if (e.target.name === 'FirstName') {
            setStatesChangeStatus(true)
            if (masterNameValues?.LastName && masterNameValues?.FirstName) {
                getNameSearch(loginAgencyID, masterNameValues?.NameTypeID, masterNameValues?.LastName, masterNameValues?.FirstName, null, null)
            }
        }
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            setChangesStatus(true)
            setStatesChangeStatus(true)
            if (name === 'DLStateID') {
                setmasterNameValues({ ...masterNameValues, [name]: e.value, ['DLNumber']: '' });
            } else {
                setmasterNameValues({ ...masterNameValues, [name]: e.value });
            }
        } else {
            setStatesChangeStatus(true)
            if (name === 'DLStateID') {
                setmasterNameValues({ ...masterNameValues, [name]: null, ['DLNumber']: '' });
            } else {
                setmasterNameValues({ ...masterNameValues, [name]: null });
            }
        }
    };

    const ChangePhoneType = (e, name) => {
        setStatesChangeStatus(true); setChangesStatus(true);
        if (e) {
            if (name === 'PhoneTypeID') {
                setPhoneTypeCode(e.id)
                setmasterNameValues({ ...masterNameValues, [name]: e.value, ['Contact']: "", })

            } else {
                setmasterNameValues({ ...masterNameValues, [name]: e.value })
            }
        } else if (e === null) {
            if (name === 'PhoneTypeID') {
                setmasterNameValues({ ...masterNameValues, ['PhoneTypeID']: "", ['Contact']: "", ['IsUnListedPhNo']: false });
                setErrors({ ...errors, ['ContactError']: 'true', ['NameTypeIDError']: '' });
                setPhoneTypeCode('')
            }
        } else {
            setmasterNameValues({ ...masterNameValues, [name]: null, ['IsUnListedPhNo']: false });
            setPhoneTypeCode('')
        }
    }

    const Reset = () => {
        setmasterNameValues({
            ...masterNameValues,
            'NameIDNumber': 'Auto Generated', 'Address': '', 'Contact': '', 'BusinessTypeID': '', 'SuffixID': '', 'DLStateID': '',
            'VerifyID': '', 'SexID': '', 'RaceID': '', 'PhoneTypeID': '', 'NameReasonCodeID': '', 'CertifiedByID': '', 'EthnicityID': '',
            'AgeUnitID': '', 'IsJuvenile': false, 'IsCurrentPh': true, 'IsVerify': true, 'IsUnListedPhNo': '', 'HeightFrom': '', 'HeightTo': '',
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'WeightFrom': '', 'WeightTo': '', 'DateOfBirth': '', 'CertifiedDtTm': '',
            'AgeFrom': '', 'OwnerNameID': '', 'OwnerPhoneNumber': '', 'OwnerFaxNumber': '', 'NameID': '', 'ArrestID': "", 'WarrantID': "",
            'AgeTo': '', 'Years': '', 'EventType': 'I', 'ModifiedByUserFK': '', 'MasterNameID': '', 'TicketID': "", 'checkVictem': 0, 'checkOffender': 0,
            'checkArrest': 0, 'CreatedByUserFK': '', 'AgencyID': '', 'IncidentID': IncID, 'NameLocationID': '', 'DLNumber': '',
        });
        setChangesStatus(false);
        setStatesChangeStatus(false);
        setIsAdult(false);
        setglobalname('');
        setglobalnameto('');
        setPhoneTypeCode(''); setPossenSinglData([]);
        const id = nameTypeIdDrp?.filter((val) => { if (val.id === "I") return val });
        if (id.length > 0) {
            setmasterNameValues(prevValues => { return { ...prevValues, ['NameTypeID']: id[0].value } })
            setNameTypeCode(id[0].NameTypeCode);
        }
        setMultiSelected({ optionSelected: [], });
    }

    const getNameSearch = async (loginAgencyID, NameTypeID, LastName, FirstName, MiddleName, DateOfBirth, SSN, type) => {
        if (LastName || DateOfBirth || LastName || FirstName || MiddleName || SSN) {
            fetchPostData("MasterName/Search_Name", {
                "NameTypeID": NameTypeID, "LastName": LastName, "FirstName": FirstName ? FirstName : null, "MiddleName": MiddleName ? MiddleName : null, "SSN": SSN ? SSN : null, 'AgencyID': loginAgencyID ? loginAgencyID : null, AgencyID: "",
                NameIDNumber: "", NameTypeID: "", NameReasonCodeID: "", SuffixID: "", DateOfBirthFrom: DateOfBirth, DateOfBirthTo: "", SexID: "", RaceID: "", EthnicityID: "", HairColorID: "", EyeColorID: "", WeightFrom: "", WeightTo: "", SMTTypeID: "", SMTLocationID: "", SMT_Description: "",
                IncidentNumber: "", IncidentNumberTo: "", ReportedDate: "", ReportedDateTo: "", DateOfBirth: "", HeightFrom: "", HeightTo: "",
            }).then((data) => {
                if (data.length > 0) {
                    setNameSearchValue(data); setNameSearchStatus(true)
                } else {
                    setNameSearchValue([]);
                    if (type) toastifyError('No Name Available');
                    setNameSearchStatus(false)
                }
            })
        } else {
            setNameSearchStatus(false);
            toastifyError('Empty Feild');
        }
    }

    // Custom Style
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 33,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const colourStylesReason = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            minHeight: 33,
        }),
    };

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 33,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 33,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            width: 130,
        }),
    };

    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef1.current.setOpen(false);
        }
    };

    const HeightFromOnBlur = (e) => {
        setStatesChangeStatus(true)
        setChangesStatus(true)
        const heightstates = e.target.value;
        var len = heightstates.length;
        let heights = "";
        var oldvalue = heightstates.substring(len - 1, len);
        if (oldvalue != "\"") {
            if (len == 0) {
                heights = '';
            }
            else if (len == 1) {
                heights = heightstates.substring(0, len) + "'00\"";
            }
            else if (len == 2) {
                heights = heightstates.substring(0, len - 1) + "'0" + heightstates.substring(len - 1) + "\"";
            }
            else {
                var lengthstate = heightstates.substring(len - 2)
                heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
                if (heightstates.substring(len - 2, len - 1) == 0) {
                    heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
                }
                if (lengthstate > 11) {

                    heights = '';
                    toastifyError('invalid');
                }
            }
        }
        else {
            heights = heightstates;
        }
        const globalname_Fromarray = globalnameto.replace("\"", "").replace("'", "");
        const globalname_Toarray = heights.replace("\"", "").replace("'", "");
        if ((parseInt(globalname_Fromarray) < parseInt(globalname_Toarray))) {
            toastifyError('height should be less');
            // heights = '';
        }
        if (parseInt(heights.replace("\"", "").replace("'", "")) < 101) {
            toastifyError('Height should be greater than or equal to 1\'01"');
            heights = '';
        }
        if (heights != '') {
            setglobalname(heights);
        }
        setmasterNameValues({
            ...masterNameValues,
            ['HeightFrom']: heights,
        })
    }

    const HeightOnChange = (e) => {
        setStatesChangeStatus(true)
        setChangesStatus(true)
        const heightstates = e.target.value;
        var len = heightstates.length;
        let heights = "";
        var oldvalue = heightstates.substring(len - 1, len);
        if (oldvalue != "\"") {
            if (len == 0) {
                heights = '';
            }
            else if (len == 1) {
                heights = heightstates.substring(0, len) + "'00\"";
            }
            else if (len == 2) {
                heights = heightstates.substring(0, len - 1) + "'0" + heightstates.substring(len - 1) + "\"";
            }
            else {
                heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
                if (heightstates.substring(len - 2, len - 1) == 0) {
                    heights = heightstates.substring(0, len - 2) + "'" + heightstates.substring(len - 2) + "\"";
                }
                var lengthstate = heightstates.substring(len - 2)
                if (lengthstate > 11) {
                    heights = '';
                    // alert("invalid");
                    toastifyError('invalid');
                }
            }

        }
        else {
            heights = heightstates;
        }

        const globalname_Fromarray = globalname.replace("\"", "").replace("'", "");
        const globalname_Toarray = heights.replace("\"", "").replace("'", "");

        if ((parseInt(globalname_Fromarray) > parseInt(globalname_Toarray))) {
            toastifyError('height should be greater');
            // heights = '';
        }
        if (parseInt(heights.replace("\"", "").replace("'", "")) < 101) {
            toastifyError('Height should be greater than or equal to 1\'01"');
            heights = '';
        }

        if (heights != '') {
            setglobalnameto(heights)
        }

        setmasterNameValues({
            ...masterNameValues,
            ['HeightTo']: heights,
        })

    }

    const setStatusFalse = () => {
        setNameModalStatus(false); Reset(); setErrors(''); setNameSearchStatus(false);
    }

    const maxDate = (incReportedDate && !isNaN(Date.parse(incReportedDate))) ? new Date(incReportedDate) : new Date();

    return (
        <>
            {
                nameModalStatus &&
                <>
                    <div class="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="MasterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                        <div class="modal-dialog  modal-dialog-centered  modal-xl">
                            <div class="modal-content">
                                <button type="button" className="border-0" aria-label="Close" onClick={() => { setStatusFalse(); setNameShowPage('home') }} data-dismiss="modal" style={{ alignSelf: "end" }} ref={crossButtonRef}><b>X</b>
                                </button>
                                <div class="modal-body name-body-model">
                                    <div className="row " >
                                        <div className="col-12 name-tab">
                                            <ul className='nav nav-tabs'>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'home' ? 'active' : ''}`}
                                                    // to={`/Name-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${NameID}&MasterNameID=${MasterNameID}&NameStatus=${NameStatus}`} data-toggle={changesStatus ? "modal" : "pill"}
                                                    // to={`#`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'home' ? 'Red' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('home'); GetSingleDataPassion(possessionID); }}>
                                                    {iconHome}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'General' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'General' ? 'Red' : MasterNameTabCount?.GeneralCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('General') }}>
                                                    General{`${MasterNameTabCount?.GeneralCount > 0 ? '(' + MasterNameTabCount?.GeneralCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'Appearance' ? 'active' : ''}${
                                                        // !possessionID || possenSinglData?.length === 0 ? 'disabled' : ''
                                                        type === "VehicleOwner" ?
                                                            !ownerOfID && possenSinglData?.length === 0 ?
                                                                'disabled' : ''
                                                            :
                                                            !possessionID && possenSinglData?.length === 0 ?
                                                                'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'Appearance' ? 'Red' : MasterNameTabCount?.AppearanceCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('Appearance') }} >
                                                    Appearance{`${MasterNameTabCount?.AppearanceCount > 0 ? '(' + MasterNameTabCount?.AppearanceCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'aliases' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'aliases' ? 'Red' : MasterNameTabCount?.AliasesCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('aliases') }}  >
                                                    Aliases{`${MasterNameTabCount?.AliasesCount > 0 ? '(' + MasterNameTabCount?.AliasesCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'SMT' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'SMT' ? 'Red' : MasterNameTabCount?.NameSMTCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('SMT') }}>
                                                    SMT{`${MasterNameTabCount?.NameSMTCount > 0 ? '(' + MasterNameTabCount?.NameSMTCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'Identification_Number' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'Identification_Number' ? 'Red' : MasterNameTabCount?.IdentificationNumberCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('Identification_Number') }}  >
                                                    Identification Number{`${MasterNameTabCount?.IdentificationNumberCount > 0 ? '(' + MasterNameTabCount?.IdentificationNumberCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'Contact_Details' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'Contact_Details' ? 'Red' : MasterNameTabCount?.ContactDetailsCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('Contact_Details') }}  >
                                                    Contact Details{`${MasterNameTabCount?.ContactDetailsCount > 0 ? '(' + MasterNameTabCount?.ContactDetailsCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'Address' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        }`}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'Address' ? 'Red' : MasterNameTabCount?.AddressCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('Address') }}    >
                                                    Address{`${MasterNameTabCount?.AddressCount > 0 ? '(' + MasterNameTabCount?.AddressCount + ')' : ''}`}
                                                </span>
                                                <span
                                                    className={`nav-item ${nameShowPage === 'documents' ? 'active' : ''}${type === "VehicleOwner" ?
                                                        !ownerOfID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        :
                                                        !possessionID && possenSinglData?.length === 0 ?
                                                            'disabled' : ''
                                                        } `}
                                                    data-toggle={changesStatus ? "modal" : "pill"}
                                                    data-target={changesStatus ? "#MasterSaveModel" : ''}
                                                    style={{ color: nameShowPage === 'documents' ? 'Red' : MasterNameTabCount?.DocumentCount > 0 ? 'blue' : '#000' }}
                                                    aria-current="page"
                                                    onClick={() => { if (!changesStatus) setNameShowPage('documents') }} >
                                                    Documents{`${MasterNameTabCount?.DocumentCount > 0 ? '(' + MasterNameTabCount?.DocumentCount + ')' : ''}`}
                                                </span>
                                            </ul>
                                        </div>
                                    </div>
                                    {nameShowPage === 'home' &&
                                        <div>
                                            <div className="m-1 mt-2">
                                                <fieldset>
                                                    <legend style={{ fontWeight: 'bold' }}>Name</legend>
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 ">
                                                            <div className="row">
                                                                <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                                    <label htmlFor="" className='new-label '>Name Type</label>
                                                                    {errors.NameTypeIDError !== 'true' ? (
                                                                        <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.NameTypeIDError}</p>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                    <Select
                                                                        name='NameTypeID'
                                                                        value={nameTypeIdDrp?.filter((obj) => obj.value === masterNameValues?.NameTypeID)}
                                                                        options={nameTypeIdDrp}
                                                                        onChange={(e) => ChangeNameType(e, 'NameTypeID')}
                                                                        isClearable
                                                                        placeholder="Select..."
                                                                        isDisabled={setArrestID || nameID || masterNameID || possessionID ? true : false}
                                                                        styles={colourStyles}
                                                                    />
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                                    <label htmlFor="" className='new-label '>MNI</label>
                                                                </div>
                                                                <div className="col-2 col-md-3 col-lg-2 text-field mt-2">
                                                                    <input type="text" className='readonlyColor' value={masterNameValues?.NameIDNumber} name='nameid' required readOnly autoComplete='off' />
                                                                </div>
                                                                <div className="col-3 col-md-2 col-lg-1 mt-2">
                                                                    <div className="form-check ">
                                                                        <input className="form-check-input" type="checkbox" name='IsJuvenile' value={value?.IsJuvenile} checked={juvinile} id="flexCheckDefault" disabled={nameTypeCode === "B" ? true : false} />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                            Juvenile
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-1 mt-2 pt-1">
                                                                    <label htmlFor="" className='new-label '>Reason Code
                                                                        {errors.NameReasonCodeIDError !== 'true' ? (
                                                                            <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.NameReasonCodeIDError}</p>
                                                                        ) : null}
                                                                    </label>
                                                                </div>
                                                                <div className="col-10 col-md-10 col-lg-4 mt-1" >
                                                                    <SelectBox
                                                                        isDisabled={type === "NameBusiness" || type === "VehicleOwner" || type === "MissingMod" ? true : false}
                                                                        styles={colourStylesReason}
                                                                        options={reasonIdDrp}
                                                                        menuPlacement="bottom"
                                                                        isMulti
                                                                        closeMenuOnSelect={false}
                                                                        hideSelectedOptions={true}
                                                                        allowSelectAll={false}
                                                                        value={multiSelected.optionSelected}
                                                                        components={{ MultiValue }}
                                                                        onChange={(e) => masterNameValues.checkVictem === 1 || masterNameValues.checkVictem === 0 && masterNameValues.checkOffender === 1 || masterNameValues.checkOffender === 0 ? OnChangeSelectedReason(e, 'NameReasonCodeID') : ''}
                                                                    />
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                                    <label htmlFor="" className='new-label '>Last Name
                                                                        {errors.LastNameError !== 'true' ? (
                                                                            <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.LastNameError}</p>
                                                                        ) : null}
                                                                    </label>
                                                                </div>
                                                                <div className="col-10 col-md-10 col-lg-2 text-field mt-2">
                                                                    <input type="text" name='LastName'
                                                                        className={'requiredColor'}
                                                                        value={masterNameValues?.LastName}
                                                                        onKeyDown={handleKeyDown}
                                                                        onChange={HandleChange} onBlur={LastFirstNameOnBlur} required
                                                                        autoComplete='off' />
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                                    <label htmlFor="" className='new-label '>First Name
                                                                        {errors.FirstNameError !== 'true' ? (
                                                                            <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.FirstNameError}</p>
                                                                        ) : null}
                                                                    </label>
                                                                </div>
                                                                <div className="col-10 col-md-10 col-lg-2 text-field mt-2">
                                                                    <input type="text" name='FirstName'
                                                                        className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                        value={masterNameValues?.FirstName}
                                                                        onKeyDown={handleKeyDown}
                                                                        onChange={HandleChange} onBlur={LastFirstNameOnBlur}
                                                                        required disabled={nameTypeCode === "B" ? true : false} readOnly={nameTypeCode === "B" ? true : false} autoComplete='off' />
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-1 mt-3 px-0 ">
                                                                    <label htmlFor="" className='new-label '>Middle Name
                                                                        {errors.MiddleNameError !== 'true' ? (
                                                                            <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.MiddleNameError}</p>
                                                                        ) : null}
                                                                    </label>
                                                                </div>
                                                                <div className="col-10 col-md-10 col-lg-2 text-field mt-2">
                                                                    <input type="text" name='MiddleName'
                                                                        value={masterNameValues?.MiddleName} className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                        onChange={HandleChange} onKeyDown={handleKeyDown} required disabled={nameTypeCode === "B" ? true : false} readOnly={nameTypeCode === "B" ? true : false} autoComplete='off' />
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-3 d-flex mt-1 ">
                                                                    <div className="col-2 col-md-2 col-lg-2 mt-2 ml-4 ml-md-0">
                                                                        <label htmlFor="" className='label-name'>Suffix</label>
                                                                    </div>
                                                                    <div className="col-10 col-md-10 col-lg-10 ">
                                                                        <Select
                                                                            name='SuffixID'
                                                                            value={suffixIdDrp?.filter((obj) => obj.value === masterNameValues?.SuffixID)}
                                                                            options={suffixIdDrp}
                                                                            onChange={(e) => ChangeDropDown(e, 'SuffixID')}
                                                                            isClearable
                                                                            placeholder="Select..."
                                                                            isDisabled={nameTypeCode === "B" ? true : false}
                                                                            styles={customStylesWithOutColor}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-6" >
                                                                    <div className="row">
                                                                        <div className="col-1 col-md-2 col-lg-2 mt-3">
                                                                            <label htmlFor="" className='label-name '>DOB
                                                                                {errors.DateOfBirthError !== 'true' ? (
                                                                                    <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.DateOfBirthError}</p>
                                                                                ) : null}</label>
                                                                        </div>
                                                                        <div className="col-2 col-md-3 col-lg-4 mt-0">
                                                                            <DatePicker
                                                                                id='DateOfBirth'
                                                                                name='DateOfBirth'
                                                                                className={nameTypeCode === "B" ? 'readonlyColor' : isAdult || type === "ArrestMod" || type === "MissingMod" ? 'requiredColor' : ''}
                                                                                // className={nameTypeCode === "B" ? 'readonlyColor' : isAdult ? 'requiredColor' : isDatePickerRequiredColor ? 'requiredColor' : ''}
                                                                                ref={startRef}
                                                                                onKeyDown={onKeyDown}
                                                                                onChange={(date, e) => { handleDOBChange(date, e); }}
                                                                                dateFormat="MM/dd/yyyy"
                                                                                isClearable={masterNameValues.DateOfBirth ? true : false}
                                                                                selected={dobDate}
                                                                                placeholderText={masterNameValues.DateOfBirth ? masterNameValues.DateOfBirth : 'Select...'}
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                autoComplete='Off'
                                                                                maxDate={maxDate}
                                                                                disabled={nameTypeCode === "B" ? true : false}
                                                                            // maxDate={new Date(incReportedDate)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 col-md-7 col-lg-4 d-flex " >
                                                                            <div className="col-1 col-md-1 col-lg-2 mt-2 ">
                                                                                <label htmlFor="" className='label-name'>Age</label>
                                                                            </div>
                                                                            <div className="col-2 col-md-3 col-lg-4 mt-1  text-field" >
                                                                                <input type="text"
                                                                                    name='AgeFrom'
                                                                                    maxLength={3}
                                                                                    value={masterNameValues?.AgeFrom} onChange={HandleChange} required
                                                                                    className={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? 'readonlyColor' : ''}
                                                                                    disabled={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? true : false}
                                                                                    readOnly={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? true : false}
                                                                                    placeholder='From' autoComplete='off' />
                                                                            </div>
                                                                            <span className='dash-name mt-1'>_</span>
                                                                            <div className="col-2 col-md-2 col-lg-4 mt-1  text-field " >
                                                                                <input type="text"
                                                                                    name='AgeTo'
                                                                                    maxLength={3} value={masterNameValues?.AgeTo}
                                                                                    onChange={HandleChange} required
                                                                                    className={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? 'readonlyColor' : ''}
                                                                                    disabled={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? true : false}
                                                                                    readOnly={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? true : false}
                                                                                    placeholder='To' autoComplete='off' />
                                                                            </div>
                                                                            <div className="col-4 col-md-4 col-lg-10  mt-1" >
                                                                                <Select
                                                                                    name='AgeUnitID'
                                                                                    value={ageUnitDrpData?.filter((obj) => obj.value === masterNameValues?.AgeUnitID)}
                                                                                    options={ageUnitDrpData}
                                                                                    isClearable
                                                                                    placeholder="Age Unit..."
                                                                                    styles={customWithOutColor}
                                                                                    className={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? 'readonlyColor' : ''}
                                                                                    isDisabled={nameTypeCode === "B" || masterNameValues?.DateOfBirth ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-6">
                                                                    <div className="row">
                                                                        <div className="col-6 col-md-6 col-lg-5 d-flex ">
                                                                            <div className="col-1 col-md-4 col-lg-5 mt-2 ">
                                                                                <label htmlFor="" className='label-name '>Gender
                                                                                    {errors.SexIDError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.SexIDError}</p>
                                                                                    ) : null}</label>
                                                                            </div>
                                                                            <div className="col-12 col-md-8 col-lg-8 mt-1">
                                                                                <Select
                                                                                    styles={(isAdult || type === "ArrestMod" || type === "MissingMod") ? colourStyles : customStylesWithOutColor}
                                                                                    // styles={isAdult ? colourStyles : customStylesWithOutColor && isDatePickerRequiredColor ? colourStyles : customStylesWithOutColor}
                                                                                    name='SexID'
                                                                                    value={sexIdDrp?.filter((obj) => obj.value === masterNameValues?.SexID)}
                                                                                    onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                                                    options={sexIdDrp}
                                                                                    isClearable
                                                                                    placeholder="Select..."
                                                                                    isDisabled={nameTypeCode === "B" ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 col-md-6 col-lg-5 d-flex ">
                                                                            <div className="col-1 col-md-2 col-lg-5 mt-2 px-0">
                                                                                <label htmlFor="" className='label-name '>Race
                                                                                    {errors.RaceIDError !== 'true' ? (
                                                                                        <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.RaceIDError}</p>
                                                                                    ) : null}</label>
                                                                            </div>
                                                                            <div className="col-12 col-md-10 col-lg-12 mt-1">
                                                                                <Select
                                                                                    name='RaceID'
                                                                                    value={raceIdDrp?.filter((obj) => obj.value === masterNameValues?.RaceID)}
                                                                                    onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                                                    options={raceIdDrp}
                                                                                    isClearable
                                                                                    placeholder="Select..."
                                                                                    isDisabled={nameTypeCode === "B" ? true : false}
                                                                                    styles={isAdult || type === "ArrestMod" || type === "MissingMod" ? colourStyles : customStylesWithOutColor}
                                                                                // styles={isAdult ? colourStyles : customStylesWithOutColor && isDatePickerRequiredColor ? colourStyles : customStylesWithOutColor}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                                    <label htmlFor="" className='new-label '>Ethnicity</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <Select
                                                                        name='EthnicityID'
                                                                        value={ethinicityDrpData?.filter((obj) => obj.value === masterNameValues?.EthnicityID)}
                                                                        onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                                        options={ethinicityDrpData}
                                                                        isClearable
                                                                        placeholder="Select..."
                                                                        styles={customStylesWithOutColor}
                                                                        isDisabled={nameTypeCode === "B" ? true : false}
                                                                        className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                    />
                                                                </div>
                                                                <div className="col-1 col-md-1 col-lg-1 mt-1">
                                                                    <label htmlFor="" className='new-label '>Weight
                                                                        <p className='text-center mb-0' style={{ fontWeight: 'bold', fontSize: '10px' }}>(LBS)</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-2 col-md-1 col-lg-1 text-field mt-1" >
                                                                    <input type="text" name='WeightFrom'
                                                                        value={masterNameValues?.WeightFrom}
                                                                        onChange={HandleChange} maxLength={3}
                                                                        required
                                                                        disabled={nameTypeCode === "B" ? true : false}
                                                                        readOnly={nameTypeCode === "B" ? true : false}
                                                                        className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                        placeholder='From' autoComplete='off' />
                                                                </div>
                                                                <span className='dash-name mt-1' >_</span>
                                                                <div className="col-3 col-md-1 col-lg-1 ">
                                                                    <div className="text-field pr-2 mt-1">
                                                                        <input type="text"
                                                                            name='WeightTo'
                                                                            value={masterNameValues?.WeightTo}
                                                                            onChange={HandleChange} maxLength={3}
                                                                            required className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                            disabled={nameTypeCode === "B" ? true : false}
                                                                            readOnly={nameTypeCode === "B" ? true : false}
                                                                            placeholder='To' autoComplete='off' />
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 col-md-1 col-lg-1 mt-1">
                                                                    <label htmlFor="" className='new-label '>Height
                                                                        {errors.HeightError !== 'true' ? (
                                                                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.HeightError}</p>
                                                                        ) : null}
                                                                        <p className='text-center mb-0 ' style={{ fontWeight: 'bold', fontSize: '10px' }}>(FT)</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-2 col-md-1 col-lg-1 text-field mt-2" >
                                                                    <input type="text"
                                                                        name='HeightFrom'
                                                                        maxLength={3}
                                                                        onChange={HandleChange}
                                                                        onBlur={(e) => {
                                                                            e.relatedTarget !== closeButtonRef.current &&
                                                                                e.relatedTarget !== saveButtonRef.current &&
                                                                                e.relatedTarget !== crossButtonRef.current &&
                                                                                e.relatedTarget !== saveAndContRef.current &&
                                                                                HeightFromOnBlur(e)
                                                                        }}
                                                                        value={masterNameValues?.HeightFrom}
                                                                        required
                                                                        disabled={nameTypeCode === "B" ? true : false}
                                                                        readOnly={nameTypeCode === "B" ? true : false}
                                                                        className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                        placeholder='From' autoComplete='off' />
                                                                </div>
                                                                <span className='dash-name mt-1' >__</span>
                                                                <div className="col-3 col-md-1 col-lg-1 ">
                                                                    <div className="text-field  mt-2">
                                                                        <input type="text"
                                                                            name='HeightTo'
                                                                            maxLength={3}
                                                                            onChange={HandleChange}
                                                                            onBlur={(e) => {
                                                                                e.relatedTarget !== closeButtonRef.current &&
                                                                                    e.relatedTarget !== saveButtonRef.current &&
                                                                                    e.relatedTarget !== crossButtonRef.current &&
                                                                                    e.relatedTarget !== saveAndContRef.current &&
                                                                                    HeightOnChange(e)
                                                                            }}
                                                                            value={masterNameValues?.HeightTo}
                                                                            required
                                                                            className={nameTypeCode === "B" ? 'readonlyColor' : ''}
                                                                            disabled={nameTypeCode === "B" ? true : false}
                                                                            readOnly={nameTypeCode === "B" ? true : false}
                                                                            placeholder='To' autoComplete='off' />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                {
                                                    nameTypeCode !== "B" &&
                                                    <fieldset >
                                                        <legend style={{ fontWeight: 'bold' }}>SSN/DL Info</legend>
                                                        <div className="col-12 col-md-12 col-lg-12 ">
                                                            <div className="row ">
                                                                <div className="col-3 col-md-2 col-lg-1 mt-2 ">
                                                                    <label htmlFor="" className='new-label '>SSN
                                                                        {errors.SsnNoError !== 'true' ? (
                                                                            <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.SsnNoError}</p>
                                                                        ) : null}
                                                                    </label>
                                                                </div>
                                                                <div className="col-3 col-md-4 col-lg-2 text-field mt-1" >
                                                                    <input type="text" className={type === "ArrestMod" ? 'requiredColor' : ''} maxLength={9} onChange={HandleChange} name='SSN' value={masterNameValues?.SSN} required autoComplete='off' />
                                                                </div>
                                                                <div className="col-3 col-md-6 col-lg-4 d-flex " >
                                                                    <div className="col-2 col-md-2 col-lg-3 mt-2">
                                                                        <label htmlFor="" className='new-label '>State/DL#</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-5 col-lg-3 mt-1">
                                                                        <Select
                                                                            name='DLStateID'
                                                                            value={dlStateDrpData?.filter((obj) => obj.value === masterNameValues?.DLStateID)}
                                                                            options={dlStateDrpData}
                                                                            onChange={(e) => ChangeDropDown(e, 'DLStateID')}
                                                                            isClearable
                                                                            placeholder="State"
                                                                            styles={customStylesWithOutColor}
                                                                        // className={(nameTypeCode === "B" || masterNameValues?.IsUnknown === 'true' || masterNameValues?.IsUnknown === true) ? 'readonlyColor' : ''}
                                                                        // isDisabled={(nameTypeCode === "B" || masterNameValues?.IsUnknown === 'true' || masterNameValues?.IsUnknown === true) ? true : false}
                                                                        />
                                                                    </div>
                                                                    <span className='dash-name mt-1' >__{errors.DLNumberError !== 'true' ? (
                                                                        <p style={{ color: 'red', fontSize: '9px', margin: '0px', padding: '0px' }}>{errors.DLNumberError}</p>
                                                                    ) : null}</span>
                                                                    <div className="col-3 col-md-5 col-lg-6 text-field mt-2" >
                                                                        <input
                                                                            type="text"
                                                                            style={{ textTransform: "uppercase" }}
                                                                            value={masterNameValues?.DLNumber}
                                                                            maxLength={21}
                                                                            className={masterNameValues?.DLStateID ? 'requiredColor' : 'readonlyColor'}
                                                                            onChange={HandleChange}
                                                                            name="DLNumber"
                                                                            required
                                                                            autoComplete='off'
                                                                            disabled={masterNameValues?.DLStateID ? false : true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-2 col-md-2 col-lg-2 mt-2">
                                                                    <label htmlFor="" className='new-label '>How Verify</label>
                                                                </div>
                                                                <div className="col-10 col-md-4 col-lg-3 mt-1">
                                                                    <Select
                                                                        name='VerifyID'
                                                                        value={verifyIdDrp?.filter((obj) => obj.value === masterNameValues?.VerifyID)}
                                                                        onChange={(e) => ChangeDropDown(e, 'VerifyID')}
                                                                        options={verifyIdDrp}
                                                                        isClearable
                                                                        placeholder="VerifyID"
                                                                        styles={customStylesWithOutColor}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }
                                                <fieldset>
                                                    <legend style={{ fontWeight: 'bold' }}>Contact Info</legend>
                                                    <div className="row">
                                                        <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                            <label htmlFor="" className='new-label '>Contact Type</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                            <Select
                                                                name='PhoneTypeID'
                                                                value={phoneTypeIdDrp?.filter((obj) => obj.value === masterNameValues?.PhoneTypeID)}
                                                                options={phoneTypeIdDrp}
                                                                onChange={(e) => ChangePhoneType(e, 'PhoneTypeID')}
                                                                isClearable
                                                                placeholder="Select..."
                                                                disabled={phoneTypeCode ? false : true}
                                                                styles={customStylesWithOutColor}
                                                            />
                                                        </div>
                                                        <div className="col-1 col-md-2 col-lg-1 mt-2">
                                                            <label htmlFor="" className='new-label '>Contact{errors.ContactError !== 'true' ? (
                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.ContactError}</p>
                                                            ) : null}
                                                            </label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text"
                                                                maxLength={phoneTypeCode !== 'E' ? 10 : ''} className={masterNameValues?.PhoneTypeID ? 'requiredColor' : 'readonlyColor'}
                                                                name='Contact' value={masterNameValues?.Contact} onChange={HandleChange} required disabled={masterNameValues?.PhoneTypeID ? false : true} autoComplete='off' />
                                                        </div>
                                                        <div className="col-3 col-md-1 col-lg-1  pt-1 ">
                                                            <div className="form-check ">
                                                                <input className="form-check-input"
                                                                    onChange={HandleChange}
                                                                    type="checkbox"
                                                                    name='IsUnListedPhNo'
                                                                    value={masterNameValues?.IsUnListedPhNo}
                                                                    checked={masterNameValues?.IsUnListedPhNo}
                                                                    disabled={!masterNameValues?.Contact ? true : false}
                                                                    id="flexCheckDefault2" />
                                                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                                    Unlisted
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>}
                                    {/* General */}
                                    {nameShowPage === 'General' && <MasterGeneral {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID, type }} />}
                                    {/* Appearance */}
                                    {nameShowPage === 'Appearance' && <MasterAppearance {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID, type }} />}
                                    {/* aliases */}
                                    {nameShowPage === 'aliases' && <MasterAliases {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID }} />}
                                    {/* SMT */}
                                    {nameShowPage === 'SMT' && <MasterSmt {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID }} />}
                                    {/* Identification_Number */}
                                    {nameShowPage === 'Identification_Number' && <MasterIdentificationNumber {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID }} />}
                                    {/* Contact_Details */}
                                    {nameShowPage === 'Contact_Details' && <MasterContactDetails {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID }} />}
                                    {/* Address */}
                                    {nameShowPage === 'Address' && <MasterAddress {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID }} />}
                                    {/* documents */}
                                    {nameShowPage === 'documents' && <MasterDocument {...{ possessionID, mstPossessionID, ownerOfID, loginAgencyID, loginPinID }} />}
                                </div>
                                <div className=" text-right col-12 bt mb-1">
                                    {
                                        type === "VehicleOwner" && nameShowPage === 'home' ?
                                            <>
                                                {
                                                    ownerOfID && mstPossessionID ?
                                                        <button type="button" className="btn btn-sm btn-success mt-2  mr-1" onClick={() => { check_Validation_Error(); }} disabled={!statesChangeStatus}>Update</button>
                                                        :
                                                        <>
                                                            <button type="button" className="btn btn-sm btn-success mt-2  mr-1" onClick={() => { check_Validation_Error(); }}>Save</button>
                                                            <button type="button" className="btn btn-sm btn-success mt-2  mr-1" onClick={() => { check_Validation_Error(); setsaveContinueStatus(true) }}>Save & Continue</button>
                                                        </>
                                                }
                                                <button type="button" onClick={() => setStatusFalse()} data-dismiss="modal" className="btn btn-sm btn-success mr-1 mt-2"  >Close</button>
                                            </>
                                            :
                                            <>
                                                {
                                                    nameShowPage === 'home' && <>
                                                        {
                                                            possessionID && mstPossessionID ?
                                                                <button type="button" className="btn btn-sm btn-success mt-2  mr-1" onClick={() => { check_Validation_Error(); }} disabled={!statesChangeStatus}>Update</button>
                                                                :
                                                                <>
                                                                    <button type="button" className="btn btn-sm btn-success mt-2  mr-1" onClick={() => { check_Validation_Error(); }} ref={saveButtonRef}>Save</button>
                                                                    <button type="button" className="btn btn-sm btn-success mt-2  mr-1" onClick={() => { check_Validation_Error(); setsaveContinueStatus(true) }} ref={saveAndContRef}>Save & Continue</button>
                                                                </>
                                                        }
                                                        <button type="button" onClick={() => setStatusFalse()} data-dismiss="modal" className="btn btn-sm btn-success mr-1 mt-2" ref={closeButtonRef}>Close</button>
                                                    </>
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div >
                    <NameSearchModal {...{ setMasterNameSearchModal, masterNameSearchModal, setValue, value, mainIncidentID, nameSearchValue, setmasterNameValues, masterNameValues, setDobDate, setUpdateStatus, updateStatus, Reset, setMultiSelected, }} />
                </>
            }
            <MasterChangesModal func={check_Validation_Error} />
        </>
    )
}

export default memo(MasterNameModel)
