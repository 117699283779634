import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { AddDeleteUpadate, fetchData, fetchPostData, ScreenPermision } from '../../hooks/Api';
import { One_Value_Search_Filter, Three_Search_FilterWith_Date } from '../../Filter/Filter';
import { AgencyContext } from '../../../Context/Agency/Index';
import { base64ToString, Decrypt_Id_Name, DecryptedList, EncryptedList, getShowingDateText, getShowingMonthDateYear, getShowingWithFixedTime, getShowingWithOutTime, stringToBase64, tableCustomStyles, } from '../../Common/Utility';
import Select from "react-select";
import '../../../style/incident.css';
import { Comman_changeArrayFormat, } from '../../Common/ChangeArrayFormat';
import Loader from '../../Common/Loader';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { useDispatch, useSelector } from 'react-redux';
import { Incident_ID, Incident_Number } from '../../../redux/actionTypes';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import OtherSummaryModel from '../SummaryModel/OtherSummaryModel';
import IncidentSummaryModel from '../SummaryModel/IncidentSummaryModel';
import { element } from 'prop-types';
import * as XLSX from 'xlsx';


const Incident = () => {

    const dispatch = useDispatch();
    const { setShowIncPage, setTabCount, setIncidentCount, incidentRecentData, setIncidentRecentData, exceptionalClearID, GetDataExceptionalClearanceID, rmsDisposition, getRmsDispositionID, incidentSearchData, setIncidentSearchData, incAdvSearchData, setIncAdvSearchData, setPropertyCount, setVehicleCount } = useContext(AgencyContext);

    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const [incSummModal, setIncSummModal] = useState(false);
    const [updateCount, setupdateCount] = useState(1);
    const [otherColID, setOtherColID] = useState('');

    // Filter Option
    const [filterIncidentIdOption, setFilterIncidentIdOption] = useState('Contains');
    const [filterRmsCfsOption, setFilterRmsCfsOption] = useState('Contains');
    const [filterOccuredToOption, setFilterOccuredToOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('');
    const [searchValue2, setSearchValue2] = useState('');
    const [searchValue3, setSearchValue3] = useState('');
    const [incidentData, setIncidentData] = useState();
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
    const [incidentFilterData, setIncidentFilterData] = useState();
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [loder, setLoder] = useState(false);

    //Assign Incident 
    const [loginAgencyID, setLoginAgencyID] = useState('');

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
            setValue({ ...value, 'AgencyID': localStoreData?.AgencyID, 'CreatedByUserFK': localStoreData?.PINID, });

        }
    }, [localStoreData]);

    const exportToExcel = () => {
        const filteredData = incidentSearchData?.map(item => ({
            'Incident': item.IncidentNumber,
            'Occured To': item.OccurredTo ? getShowingDateText(item.OccurredTo) : " ",
            'Reported Date/Time': item.ReportedDate ? getShowingDateText(item.ReportedDate) : " ",
            'Location': item?.CrimeLocation,

        }));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const [value, setValue] = useState({
        'ReportedDate': getShowingWithOutTime(new Date()),
        'ReportedDateTo': getShowingWithFixedTime(new Date()),
        'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '', 'RMSCFSCodeList': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'RMSDispositionId': '',
        'DispositionDate': '', 'DispositionDateTo': '', 'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityID': '', 'PINID': '',
        'AgencyID': '',
    });

    useEffect(() => {
        if (loginAgencyID) {
            getIncidentData();
        }
        setShowIncPage('home');
    }, [loginAgencyID, incAdvSearchData])

    const getIncidentData = async () => {
        if (!incAdvSearchData) {
            fetchPostData('Incident/Search_Incident', value).then((res) => {

                if (res) {
                    setIncidentFilterData(res); setAdvancedSearch(false); setLoder(true); setIncidentSearchData([]);
                    reset_Fields();
                } else {
                    setLoder(true); setIncidentFilterData([]); setIncidentSearchData([])
                }
            })
        } else {
            setAdvancedSearch(false); setLoder(true); reset_Fields();
        }
    }

    const getIncidentSearchData = async () => {
        fetchPostData('Incident/Search_Incident', value).then((res) => {
            if (res.length > 0) {
                setIncAdvSearchData(false); setIncidentFilterData(res); setAdvancedSearch(false); setLoder(true);
                reset_Fields();
            } else {
                toastifyError("No Data Available"); setLoder(true); setIncidentFilterData([]);
            }
        });
    }

    const onClick_Reset = () => {
        setValue({ ...value, 'ReportedDate': getShowingWithOutTime(new Date()), 'ReportedDateTo': getShowingWithFixedTime(new Date()), });
        setCurrentDateData()
    }

    const setCurrentDateData = () => {
        const val = {
            'ReportedDate': getShowingWithOutTime(new Date()),
            'ReportedDateTo': getShowingWithFixedTime(new Date()),
            'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '', 'RMSCFSCodeList': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'RMSDispositionId': '',
            'DispositionDate': '', 'DispositionDateTo': '', 'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityID': '', 'PINID': '',
            'AgencyID': loginAgencyID,
        }
        fetchPostData('Incident/Search_Incident', val).then((res) => {
            if (res.length > 0) {
                setIncAdvSearchData(false); setIncidentFilterData(res); setAdvancedSearch(false); setLoder(true);
                reset_Fields();
            } else {
                toastifyError("No Data Available"); setLoder(true); setIncidentFilterData([]);
            }
        });

    }

    const getScreenPermision = (LoginAgencyID, PinID) => {
        ScreenPermision("I034", LoginAgencyID, PinID).then(res => {
            if (res) {

                setEffectiveScreenPermission(res);
            } else {
                setEffectiveScreenPermission([])
            }
        });
    }

    const columns = [
        {
            width: "80px",
            omit: incAdvSearchData == true ? false : true,
            // omit: true,
            cell: row =>
                <div className="div" >
                    <a data-toggle="modal" data-target="#IncSummaryModel"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => {
                            setupdateCount(updateCount + 1);
                            if (row?.IncidentID) {

                                setIncSummModal(true);
                                setOtherColID(row?.IncidentID);
                            }
                        }}
                    >
                        MS
                    </a>
                </div>,
        },
        {
            width: "80px",
            omit: incAdvSearchData == "kdsjfhkjsdfsdf" ? true : false,
            name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
            cell: row => (
                <div style={{ position: 'absolute', top: 4, }}>
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.Changeok ?
                                <Link to={`/Inc-Home?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.IncidentNumber}&IncSta=${true}`}
                                    onClick={(e) => { set_IncidentId(row); }}
                                    className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                                    <i className="fa fa-edit"></i>
                                    {/* <FontAwesomeIcon icon={faPenToSquare} /> */}
                                </Link>
                                : <></>
                            : <Link to={`/Inc-Home?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.IncidentNumber}&IncSta=${true}`}
                                onClick={(e) => { set_IncidentId(row); }}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                                <i className="fa fa-edit"></i>
                                {/* <FontAwesomeIcon icon={faPenToSquare} /> */}
                            </Link>
                    }
                </div>)
        },
        {
            width: '140px',
            name: 'Incident',
            selector: (row) => row.IncidentNumber,
            sortable: true
        },
        {
            width: '180px',
            name: 'Occurred  To',
            selector: (row) => row.OccurredTo ? getShowingDateText(row.OccurredTo) : " ",
            sortable: true
        },
        {
            width: '180px',
            name: 'Reported Date/Time',
            selector: (row) => row.ReportedDate ? getShowingDateText(row.ReportedDate) : " ",
            sortable: true
        },
        {
            name: 'Location',
            selector: (row) => <>{row?.CrimeLocation ? row?.CrimeLocation.substring(0, 30) : ''}{row?.CrimeLocation?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },
    ]


    const set_IncidentId = (row) => {
        let newData = [...incidentRecentData];
        let currentItem = newData.find((item) => row.IncidentID === item.IncidentID);
        if (!currentItem) {
            newData.push(row);
        }
        setIncidentRecentData(newData);
        if (row.IncidentID) {
            dispatch({ type: Incident_ID, payload: row?.IncidentID });
            dispatch({ type: Incident_Number, payload: row?.IncidentNumber });
        }
    }

    const reset_Fields = () => {
        setValue({
            ...value,
            'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '', 'RMSCFSCodeList': '',
            'OccurredFrom': '', 'OccurredFromTo': '', 'RMSDispositionId': '', 'DispositionDate': '', 'DispositionDateTo': '',
            'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityID': '', 'PINID': '',
        });
    }

    useEffect(() => {
        if (advancedSearch) {
            if (loginAgencyID) {
                getRmsDispositionID(loginAgencyID); GetDataExceptionalClearanceID(loginAgencyID);
            }
        }
    }, [loginAgencyID, advancedSearch]);

    const click_AdvanceSearch = () => {
        setValue({ ...value, 'ReportedDate': '', 'ReportedDateTo': '', });
        // setAdvancedSearch(true);
    }

    return (
        <>
            <div className="col-12">
                <div className="d-flex mt-2">
                    <div className="md col-5">
                        <h6 className="d-inline-block form-set p-2 text-dark text-bold" >
                            {/* Welcome */}
                            {/* Welcome ,{userName ? userName : ''} Incident */}
                        </h6>
                        <small className="d-block">
                        </small>
                    </div>
                    <div className="col-7">
                        <div className="row" style={{ marginTop: '-10px' }}>
                            <div className="col-3 col-md-3 col-lg-4 mb-1">
                                <div className="dropdown__box">
                                    <DatePicker
                                        name='ReportedDate'
                                        id='ReportedDate'
                                        onChange={(date) => {
                                            // console.log(date)
                                            if (date) {
                                                setValue({
                                                    ...value,
                                                    ['ReportedDate']: date ? getShowingWithOutTime(date) : null,
                                                    ['ReportedDateTo']: getShowingWithFixedTime(new Date())
                                                })
                                            } else {
                                                setValue({
                                                    ...value,
                                                    ['ReportedDate']: null,
                                                    ['ReportedDateTo']: null,
                                                })
                                            }
                                        }}
                                        selected={value.ReportedDate && new Date(value.ReportedDate)}
                                        dateFormat="MM/dd/yyyy"
                                        timeInputLabel
                                        isClearable={value?.ReportedDate ? true : false}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete='Off'
                                        disabled={false}
                                        maxDate={new Date()}
                                        placeholderText='Select From Date...'
                                    />
                                    {/* <label htmlFor="" className='pl-0 pt-1' >Reported From Date</label> */}
                                </div>
                            </div>
                            <div className="col-3 col-md-3 col-lg-5 mb-1">
                                <div className="dropdown__box">
                                    <DatePicker
                                        name='ReportedDateTo'
                                        id='ReportedDateTo'
                                        onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingWithFixedTime(date) : null }) }}
                                        selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                        dateFormat="MM/dd/yyyy"
                                        timeInputLabel
                                        isClearable={value?.ReportedDateTo ? true : false}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete='Off'
                                        minDate={new Date(value?.ReportedDate)}
                                        disabled={value?.ReportedDate ? false : true}
                                        maxDate={new Date()}
                                        placeholderText='Select To Date...'
                                    />
                                    {/* <label htmlFor="" className='pl-0 pt-1' >Reported To Date</label>    */}
                                </div>
                            </div>
                            <div className="col-3 col-md-3 col-lg-3 mt-3 pt-1">
                                <button className="btn btn-sm bg-green text-white px-2 py-1 mr-2" onClick={() => { getIncidentSearchData(); }}>
                                    Search
                                </button>
                                <button className="btn btn-sm bg-green text-white px-2 py-1 " onClick={() => { onClick_Reset(); }}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-body mt-4" style={{ margin: '10px 10px 10px 15px' }}>
                <div className="row clearfix">
                    <div className="main-dashboard col-12 mb-2 mt-2">
                        <div className="col-12 col-sm-12">
                            {
                                loder ?
                                    <DataTable
                                        columns={columns}
                                        persistTableHead={true}
                                        dense
                                        data={effectiveScreenPermission ?
                                            effectiveScreenPermission[0]?.DisplayOK ?
                                                incidentSearchData.length > 0 && incAdvSearchData ? incidentSearchData : incidentFilterData && !incAdvSearchData ? incidentFilterData : []
                                                : ""
                                            : ""
                                        }
                                        pagination
                                        paginationPerPage={'100'}
                                        paginationRowsPerPageOptions={[100, 150, 200, 500]}
                                        showPaginationBottom={100}
                                        // paginationRowsPerPageOptions={[10, 15, 20]}
                                        highlightOnHover
                                        subHeader
                                        responsive
                                        fixedHeaderScrollHeight='380px'
                                        fixedHeader
                                        customStyles={tableCustomStyles}
                                        subHeaderComponent={
                                            <div className="col-12 px-0 mt-1">
                                                <div className="row px-0">
                                                    <div className="col-3 col-md-4 col-lg-8 mt-2 px-0 pl-0">
                                                        <p className="new-para ">Incident</p>
                                                    </div>
                                                    <div className="col-3 col-md-8 col-lg-4 ">
                                                        <button className="btn btn-sm new-btn  py-1 mr-2">
                                                            <FontAwesomeIcon icon={faFilter} className="react-icon pl-1" />
                                                            Filter
                                                        </button>
                                                        <Link to='/incident-advanceSearch'>
                                                            <button className="btn btn-sm  new-btn py-1 mr-1" onClick={() => { click_AdvanceSearch(); }} >Advance Search</button>
                                                        </Link>
                                                        {
                                                            effectiveScreenPermission ?
                                                                effectiveScreenPermission[0]?.AddOK ?
                                                                    <Link
                                                                        to={`/Inc-Home?IncId=${0}&IncNo=${''}&IncSta=${false}`}
                                                                        onClick={() => {
                                                                            setShowIncPage('home');
                                                                            setPropertyCount([]); setTabCount([]); setIncidentCount([]);
                                                                            setVehicleCount([])
                                                                        }}
                                                                        className="btn btn-sm text-white py-0"
                                                                    >
                                                                        <button className="btn btn-sm bg-green text-white  py-1 " >
                                                                            {/* <i className="fa fa-plus px-0"> */}
                                                                            <FontAwesomeIcon icon={faPlus} className='px-0 react-icon' />
                                                                            <span className=''>Add Incident</span>
                                                                        </button>
                                                                    </Link>
                                                                    :
                                                                    <>
                                                                    </>
                                                                :
                                                                <>
                                                                    <Link
                                                                        to={`/Inc-Home?IncId=${0}&IncNo=${''}&IncSta=${false}`}
                                                                        onClick={() => {
                                                                            setShowIncPage('home');
                                                                            setPropertyCount([]); setTabCount([]); setIncidentCount([]);
                                                                            setVehicleCount([])
                                                                        }}
                                                                        className="btn btn-sm text-white py-0"
                                                                    >
                                                                        <button className="btn btn-sm bg-green text-white  py-1 " >
                                                                            <FontAwesomeIcon icon={faPlus} className='px-0 react-icon' />
                                                                            <span className=''>Add Incident</span>
                                                                        </button>
                                                                    </Link>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        subHeaderAlign='left'
                                        noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                                    />
                                    :
                                    <Loader />
                            }
                        </div>
                    </div>
                </div >
            </div>
            <div className="col-12 text-right">
                {
                    incidentSearchData.length > 0 ?
                        <button type="button" onClick={exportToExcel} className="btn btn-sm btn-primary mr-1"
                        >
                            <i className="fa fa-file-excel-o mr-1" aria-hidden="true"></i>
                            Export to Excel</button> :
                        <></>

                }
            </div>
            <IncidentSummaryModel {...{ setIncSummModal, incSummModal, otherColID, updateCount }} />
        </>
    )
}

export default Incident;