import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Decrypt_Id_Name, Encrypted_Id_Name, colourStyles, customStylesWithOutColor, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, getYearWithOutDateTime } from '../../../Common/Utility';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { fetchPostData } from '../../../hooks/Api';
import { toastifyError } from '../../../Common/AlertMsg';
import { Comman_changeArrayFormat, threeColArray } from '../../../Common/ChangeArrayFormat';
import DataTable from 'react-data-table-component';
import { get_BoatModel_Drp_Data, get_Bottom_Color_Drp_Data, get_Make_Drp_Data, get_Material_Drp_Data, get_MeasureType_Drp_Data, get_PropertyLossCode_Drp_Data, get_PropertyTypeData, get_Propulusion_Drp_Data, get_State_Drp_Data, get_SuspectedDrug_Drp_Data, get_Top_Color_Drp_Data, get_VOD_Drp_Data, get_WeaponMake_Drp_Data, get_WeaponModel_Drp_Data } from '../../../../redux/actions/DropDownsData';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { Property_LossCode_Drp_Data } from '../../../../redux/actionTypes';
import { useReactToPrint } from 'react-to-print';


const PropertyInventoryReport = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const propertyTypeData = useSelector((state) => state.DropDown.propertyTypeData);
    const propertyLossCodeDrpData = useSelector((state) => state.DropDown.propertyLossCodeDrpData);
    const topColorDrpData = useSelector((state) => state.DropDown.topColorDrpData);
    const bottomColorDrpData = useSelector((state) => state.DropDown.bottomColorDrpData);
    const vodDrpData = useSelector((state) => state.DropDown.vodDrpData);
    const stateDrpData = useSelector((state) => state.DropDown.stateDrpData);
    const materialDrpData = useSelector((state) => state.DropDown.materialDrpData);
    const makeDrpData = useSelector((state) => state.DropDown.makeDrpData);
    const boatModelDrpData = useSelector((state) => state.DropDown.boatModelDrpData);
    const propulusionDrpData = useSelector((state) => state.DropDown.propulusionDrpData);
    const measureTypeDrpData = useSelector((state) => state.DropDown.measureTypeDrpData);
    const weaponMakeDrpData = useSelector((state) => state.DropDown.weaponMakeDrpData);
    const weaponModelDrpData = useSelector((state) => state.DropDown.weaponModelDrpData);
    const suspectedDrugDrpData = useSelector((state) => state.DropDown.suspectedDrugDrpData);

    const { setPropertySearchData } = useContext(AgencyContext);
    const [weaponfactureDate, setWeaponfactureDate] = useState();
    const [weaponfactureDateto, setWeaponfactureDateTo] = useState();
    const [manufactureDate, setManufactureDate] = useState();
    const [manufactureDateto, setManufactureDateTo] = useState();
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [propertyCategoryData, setPropertyCategoryData] = useState([]);
    const [propertyClassificationData, setPropertyClassificationData] = useState([]);
    const [editval, setEditval] = useState([]);
    const [verifyReport, setverifyReport] = useState(false);
    const [reportedData, setReportedData] = useState([]);
    const [multiImage, setMultiImage] = useState([]);

    const [value, setValue] = useState({
        'IncidentNumber': '', 'IncidentNumberTo': '', 'PropertyNumber': '', 'PropertyNumberTo': '', 'PropertyTypeID': null, 'LossCodeID': null, 'ReportedDtTm': '', 'ReportedDtTmTo': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'DateTimeIssued': '', 'DateTimeIssuedTo': '',
        'WarrantNumber': '', 'WarrantNumberTo': '',
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'AgencyID': loginAgencyID,
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);


    useEffect(() => {
        if (loginAgencyID) {
            dispatch(get_PropertyTypeData(loginAgencyID));
            if (topColorDrpData?.length === 0) dispatch(get_Top_Color_Drp_Data(loginAgencyID))
            if (bottomColorDrpData?.length === 0) dispatch(get_Bottom_Color_Drp_Data(loginAgencyID))
            if (vodDrpData?.length === 0) dispatch(get_VOD_Drp_Data(loginAgencyID));
            if (stateDrpData?.length === 0) dispatch(get_State_Drp_Data());
            if (materialDrpData?.length === 0) dispatch(get_Material_Drp_Data(loginAgencyID))
            if (makeDrpData?.length === 0) dispatch(get_Make_Drp_Data(loginAgencyID))
            if (boatModelDrpData?.length === 0) dispatch(get_BoatModel_Drp_Data(loginAgencyID));
            if (propulusionDrpData?.length === 0) dispatch(get_Propulusion_Drp_Data(loginAgencyID));
            if (measureTypeDrpData?.length === 0) dispatch(get_MeasureType_Drp_Data(loginAgencyID));
            if (weaponMakeDrpData?.length === 0) dispatch(get_WeaponMake_Drp_Data(loginAgencyID));
            if (weaponModelDrpData?.length === 0) dispatch(get_WeaponModel_Drp_Data(loginAgencyID));
            if (suspectedDrugDrpData?.length === 0) dispatch(get_SuspectedDrug_Drp_Data(loginAgencyID));
            dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));

        }
    }, [loginAgencyID]);

    const handlChange = (e,) => {
        if (e.target.name === 'PropertyNumber' || e.target.name === 'PropertyNumberTo') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 10) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\w{3})(\d{7})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const getPropertySearch = () => {
        if (value?.ReportedDtTm?.trim()?.length > 0 || value?.PropertyNumber?.trim()?.length > 0 || value?.PropertyNumberTo?.trim()?.length > 0 || value?.IncidentNumber?.trim()?.length > 0 || value?.IncidentNumberTo?.trim()?.length > 0 || value?.ReportedDtTmTo?.trim()?.length > 0 || value?.OccurredFrom?.trim()?.length > 0 || value?.OccurredFromTo?.trim()?.length > 0 || value?.DateTimeIssued?.trim()?.length > 0 || value?.DateTimeIssuedTo?.trim()?.length > 0 || value?.WarrantNumber?.trim()?.length > 0 || value?.WarrantNumberTo?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.FirstName?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || (value?.PropertyTypeID !== null && value?.PropertyTypeID != '')) {
            const {
                ReportedDtTm, ReportedDtTmTo, CategoryID, PropertyNumber, PropertyNumberTo, PropertyTypeID, IncidentNumber, IncidentNumberTo, OccurredFrom, OccurredFromTo, DateTimeIssued, DateTimeIssuedTo, WarrantNumber, WarrantNumberTo, LastName, FirstName, MiddleName

            } = value
            const val = {
                'ReportedDtTm': ReportedDtTm, 'ReportedDtTmTo': ReportedDtTmTo, 'CategoryID': CategoryID, 'PropertyNumber': PropertyNumber, 'PropertyNumberTo': PropertyNumberTo, 'PropertyTypeID': PropertyTypeID, 'AgencyID': loginAgencyID,
                'IncidentNumber': IncidentNumber,
                'IncidentNumberTo': IncidentNumberTo,
                'OccurredFrom': OccurredFrom,
                'OccurredFromTo': OccurredFromTo,
                'DateTimeIssued': DateTimeIssued, 'DateTimeIssuedTo': DateTimeIssuedTo, 'WarrantNumber': WarrantNumber,
                'WarrantNumberTo': WarrantNumberTo, 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName,


            }
            fetchPostData('ReportPropertyInventory/GetData_ReportPropertyInventory', val).then((res) => {
                if (res.length > 0) {
                    console.log(res)
                    setReportedData(res[0]);
                    setverifyReport(true);
                    getAgencyImg(loginAgencyID)

                }
                else {
                    toastifyError("Data Not Available"); setverifyReport(false); setReportedData([]);
                }
            })
        } else {
            toastifyError("Please Enter Details");
        }
    }
    const getAgencyImg = (loginAgencyID) => {
        const val = { 'AgencyID': loginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                // console.log(res)
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);

            }
            else { console.log("errror") }
        })
    }

    useEffect(() => {
        if (value.PropertyCategoryCode) ResetFields_On_Change(value.PropertyCategoryCode);
    }, [value.PropertyCategoryCode])

    const ResetFields_On_Change = (Code) => {
        //Boat 
        if (Code !== 'B') {
            setValue({
                ...value,
                'BoatIDNumber': '', 'ManufactureYearFrom': '', 'ManufactureYearTo': '', 'Length': '', 'RegistrationStateID': '', 'RegistrationNumber': '', 'VODID': null, 'MaterialID': null,
                'MakeID': '', 'ModelID': '', 'Comments': '', 'HIN': '', 'RegistrationExpiryDtTm': '', 'PropulusionID': '', 'BottomColorID': '', 'TopColorID': '',
            });
        }
        //Article
        if (Code !== 'A') {
            setValue({
                ...value,
                'SerialID': '', 'ModelID': '', 'TopColorID': '', 'BottomColorID': '', 'OAN': '', 'Quantity': '', 'Brand': '',
            })
        }
        //Other
        if (Code !== 'O') {
            setValue({
                ...value,
                'OtherID': null, 'Brand': '', 'SerialID': '', 'BottomColorID': '', 'ModelID': '', 'Quantity': '', 'QuantityUnitID': '',
            })
        }
        //Security
        if (Code !== 'S') {
            setValue({
                ...value,
                'SecurityIDNumber': '', 'Denomination': '', 'IssuingAgency': '', 'MeasureTypeID': null, 'SecurityDtTm': '', 'SerialID': '',
            })
        }
        //Weapon
        if (Code !== 'G') {
            setValue({
                ...value,
                'WeaponIDNumber': '', 'Style': '', 'Finish': '', 'Caliber': '', 'Handle': '', 'SerialID': '', 'MakeID': '', 'WeaponModelID': null, 'IsAuto': '', 'ManufactureYearFrom': '', 'ManufactureYearTo': '',
                'BarrelLength': '',
            })
        }
    }

    const Reset = () => {
        setValue({
            ...value,
            'IncidentNumber': '', 'IncidentNumberTo': '', 'PropertyNumber': '', 'PropertyNumberTo': '', 'PropertyTypeID': null, 'LossCodeID': null, 'ReportedDtTm': '', 'ReportedDtTmTo': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'DateTimeIssued': '', 'DateTimeIssuedTo': '',
            'WarrantNumber': '', 'WarrantNumberTo': '',
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'AgencyID': loginAgencyID,
        })
    }

    const HandleChanges = (e) => {
        if (e.target.name === 'IsEvidence' || e.target.name === 'IsSendToPropertyRoom' || e.target.name === 'IsPropertyRecovered' || e.target.name === 'IsAuto') {
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
        } else if (e.target.name === 'EstimatedDrugQty') {
            let ele = e.target.value.replace(/[^0-9.]/g, "")
            if (ele.length === 10) {
                const cleaned = ('' + ele).replace(/[^0-9.]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: cleaned
                });
            } else {
                ele = e.target.value.split('$').join('').replace(/[^0-9.]/g, "");
                setValue({
                    ...value,
                    [e.target.name]: ele
                });
            }
        } else if (e.target.name === 'Quantity' || e.target.name === 'Length' || e.target.name === 'FractionDrugQty' || e.target.name === 'MarijuanaNumber' || e.target.name === 'ClandistineLabsNumber') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            });
        }
        else if (e.target.name === 'Value') {
            const ele = e.target.value.replace(/[^0-9]/g, "")
            if (ele.includes('.')) {
                if (ele.length === 16) {
                    setValue({ ...value, [e.target.name]: ele });
                } else {
                    if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
                        setValue({ ...value, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) });
                    } else { setValue({ ...value, [e.target.name]: ele }) }
                }
            } else {
                setValue({
                    ...value,
                    [e.target.name]: ele
                });
            }
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }


    // const ChangeDropDown = (e, name) => {
    //     if (e) {
    //         if (name === 'PropertyTypeID') {
    //             switch (e.id) {
    //                 case 'A': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', '')); break;
    //                 case 'B': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '1', '', '', '', '')); break;
    //                 case 'S': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '1', '', '', '')); break;
    //                 case 'O': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '1', '', '')); break;
    //                 case 'D': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '1', '')); break;
    //                 case 'G': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '', '1')); break;
    //                 default: dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));
    //             }
    //             PropertyCategory(e.value);
    //             setValue({ ...value, ['PropertyCategoryCode']: e.id, ['PropertyTypeID']: e.value, ['LossCodeID']: '', });
    //         } else {
    //             setValue({ ...value, [name]: e.value });
    //         }

    //     }

    //     else {
    //         if (name === 'PropertyTypeID') {
    //             setValue({ ...value, ['PropertyCategoryCode']: '', ['PropertyTypeID']: '', ['LossCodeID']: '', });
    //             // dispatch({ type: Property_LossCode_Drp_Data, payload: [] });
    //             dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));
    //             // PropertyCategory(id[0]?.value);
    //             // PropertyCategory(CategoryID);
    //             // PropertyClassification(PropertyDescID);
    //             return;
    //         }
    //         setValue({ ...value, [name]: null });
    //     }
    // }

    const ChangeDropDown = (e, name) => {
        if (e) {
            //   setStatesChangeStatus(true)
            if (name === 'SuspectedDrugTypeID') {
                // setDrugTypecode(e.id)
                // setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: e.value, 'SuspectedDrugType_Description': e.label, 'PropertySourceDrugTypeID': '', 'TypeMarijuana': '', 'MarijuanaNumber': '', '  ClandistineLabsNumber': '', 'DrugManufactured': '',
                });
            } else if (name === 'PropertyTypeID') {
                switch (e.id) {
                    case 'A': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', '')); break;
                    case 'B': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '1', '', '', '', '')); break;
                    case 'S': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '1', '', '', '')); break;
                    case 'O': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '1', '', '')); break;
                    case 'D': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '1', '')); break;
                    case 'G': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '', '1')); break;
                    default: dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));;
                }
                PropertyCategory(e.value);
                // setChangesStatus(true);
                setValue({ ...value, ['PropertyCategoryCode']: e.id, ['PropertyTypeID']: e.value, ['ClassificationID']: null, ['LossCodeID']: null, ['Value']: '', });
                // setLossCode('');
                // setDrugLoder(true);
            } else if (name === 'CategoryID') {
                PropertyClassification(e.value);
                // setChangesStatus(true)
                setValue({ ...value, [name]: e.value });
            }
            //    else if (name === "PossessionOfID") {
            //     setPossessionID(e.value); setPossenSinglData([]); setValue({ ...value, [name]: e.value });
            //   }
            //    else if (name === "MeasurementTypeID") {
            //     setValue({ ...value, [name]: e.value, 'PropertyDrugMeasure_Description': e.label });
            //   }
            else {
                // setChangesStatus(true)
                setValue({ ...value, [name]: e.value });
            }
        } else {
            //   setStatesChangeStatus(true)
            if (name === 'SuspectedDrugTypeID') {
                // setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: null
                });
                // setDrugTypecode('');
            } else if (name === 'PropertyTypeID') {
                // setChangesStatus(true);
                setValue({
                    ...value,
                    ['PropertyTypeID']: null, ['PropertyCategoryCode']: '', ['CategoryID']: null, ['ClassificationID']: null, ['LossCodeID']: null, ['Value']: '',
                });
                setPropertyCategoryData([]); setPropertyClassificationData([]);
                dispatch({ type: Property_LossCode_Drp_Data, payload: [] });
            } else if (name === 'CategoryID') {
                // setChangesStatus(true);
                setValue({ ...value, ['CategoryID']: null, ['ClassificationID']: null, });
                setPropertyClassificationData([]);
            }
            //    else if (name === "PossessionOfID") {
            //     setPossessionID(''); setPossenSinglData([])
            //     setChangesStatus(true);
            //     setValue({ ...value, [name]: null });
            //   } 
            else {
                // setChangesStatus(true);
                setValue({ ...value, [name]: null });
            }
            void 0;
        }
    }

    const onClose = () => {
        navigate('/dashboard-page'); Reset();
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
        }
    };
    const PropertyCategory = (CategoryID) => {
        const val = { CategoryID: CategoryID, }
        fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
            if (data) {
                setPropertyCategoryData(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
            } else {
                setPropertyCategoryData([]);
            }
        })
    }
    const PropertyClassification = (PropertyDescID) => {
        const val = { PropertyDescID: PropertyDescID, }
        fetchPostData('Property/GetDataDropDown_PropertyClassification', val).then((data) => {
            if (data) {
                setPropertyClassificationData(Comman_changeArrayFormat(data, 'PropertyClassificationID', 'Description'))
            } else {
                setPropertyClassificationData([]);
            }
        })
    }
    const componentRef = useRef();

    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })
    return (
        <div className=" section-body pt-3 p-1 bt" >
            <div className="div">
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency  ">
                            <div className="card-body" >
                                <div className="row ">
                                    <div className="col-12 ">
                                        <fieldset>
                                            <legend>Property Inventory Information</legend>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Property No.From</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type="text" id='PropertyNumber' style={{ textTransform: "uppercase" }} maxLength={10} name='PropertyNumber' value={value.PropertyNumber} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Property No.To</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type="text" id='PropertyNumberTo' style={{ textTransform: "uppercase" }} maxLength={10} name='PropertyNumberTo' value={value.PropertyNumberTo} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Incident No.From</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='IncidentNumber' style={{ textTransform: "uppercase" }} maxLength={10} name='IncidentNumber' value={value.IncidentNumber} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Incident No.To</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='IncidentNumberTo' style={{ textTransform: "uppercase" }} maxLength={10} name='IncidentNumberTo' value={value.IncidentNumberTo} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Warrant No.From</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='WarrantNumber' style={{ textTransform: "uppercase" }} maxLength={10} name='WarrantNumber' value={value.WarrantNumber} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Warrant No.To</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='WarrantNumberTo' style={{ textTransform: "uppercase" }} maxLength={10} name='WarrantNumberTo' value={value.WarrantNumberTo} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Reported From Date</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3">
                                                    <DatePicker
                                                        id='ReportedDtTm'
                                                        name='ReportedDtTm'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setValue({ ...value, ['ReportedDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        className=''
                                                        dateFormat="MM/dd/yyyy"
                                                        timeInputLabel
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        autoComplete='Off'
                                                        showYearDropdown
                                                        isClearable={value?.ReportedDtTm ? true : false}
                                                        selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                                                        maxDate={new Date()}
                                                        placeholderText={value?.ReportedDtTm ? value.ReportedDtTm : 'Select...'}
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Reported To Date</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3">
                                                    <DatePicker
                                                        id='ReportedDtTmTo'
                                                        name='ReportedDtTmTo'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setValue({ ...value, ['ReportedDtTmTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        className=''
                                                        dateFormat="MM/dd/yyyy"
                                                        timeInputLabel
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        autoComplete='Off'
                                                        showYearDropdown
                                                        disabled={value?.ReportedDtTm ? false : true}
                                                        isClearable={value?.ReportedDtTmTo ? true : false}
                                                        selected={value?.ReportedDtTmTo && new Date(value?.ReportedDtTmTo)}
                                                        maxDate={new Date()}
                                                        minDate={new Date(value?.ReportedDtTm)}
                                                        placeholderText={'Select...'}
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Occurred From Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3">
                                                    <DatePicker
                                                        name='OccurredFrom'
                                                        id='OccurredFrom'
                                                        ref={startRef2}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => {
                                                            if (date) {
                                                                setValue({ ...value, ['OccurredFrom']: date ? getShowingDateText(date) : null })
                                                            } else {
                                                                setValue({ ...value, ['OccurredFrom']: null, ['OccurredFromTo']: null })
                                                            }
                                                        }}
                                                        selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.OccurredFrom ? true : false}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        // disabled
                                                        maxDate={new Date()}
                                                        placeholderText='Select...'
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Occurred To Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3">
                                                    <DatePicker
                                                        id='OccurredFromTo'
                                                        name='OccurredFromTo'
                                                        ref={startRef3}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setValue({ ...value, ['OccurredFromTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={value?.OccurredFromTo ? true : false}
                                                        disabled={value?.OccurredFrom ? false : true}
                                                        selected={value?.OccurredFromTo && new Date(value?.OccurredFromTo)}
                                                        minDate={new Date(value?.OccurredFrom)}
                                                        maxDate={new Date()}
                                                        placeholderText={'Select...'}
                                                        showDisabledMonthNavigation
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Issued From Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3">
                                                    <DatePicker
                                                        name='DateTimeIssued'
                                                        id='DateTimeIssued'
                                                        ref={startRef2}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => {
                                                            if (date) {
                                                                setValue({ ...value, ['DateTimeIssued']: date ? getShowingDateText(date) : null })
                                                            } else {
                                                                setValue({ ...value, ['DateTimeIssued']: null, ['DateTimeIssuedTo']: null })
                                                            }
                                                        }}
                                                        selected={value?.DateTimeIssued && new Date(value?.DateTimeIssued)}
                                                        dateFormat="MM/dd/yyyy"
                                                        timeInputLabel
                                                        isClearable={value?.DateTimeIssued ? true : false}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        // disabled
                                                        maxDate={new Date()}
                                                        placeholderText='Select...'
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Occurred To Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3">
                                                    <DatePicker
                                                        id='DateTimeIssuedTo'
                                                        name='DateTimeIssuedTo'
                                                        ref={startRef3}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setValue({ ...value, ['DateTimeIssuedTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={value?.DateTimeIssuedTo ? true : false}
                                                        disabled={value?.DateTimeIssued ? false : true}
                                                        selected={value?.DateTimeIssuedTo && new Date(value?.DateTimeIssuedTo)}
                                                        minDate={new Date(value?.DateTimeIssued)}
                                                        maxDate={new Date()}
                                                        placeholderText={'Select...'}
                                                        showDisabledMonthNavigation
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row bt mt-1">
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Type</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 mt-2 ">
                                                    <Select
                                                        styles={colourStyles}
                                                        name='PropertyTypeID'
                                                        value={propertyTypeData?.filter((obj) => obj.value === value?.PropertyTypeID)}
                                                        options={propertyTypeData}
                                                        onChange={(e) => ChangeDropDown(e, 'PropertyTypeID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    // isDisabled={sessionStorage.getItem('PropertyID') || sessionStorage.getItem('MasterPropertyID') ? true : false}
                                                    />
                                                </div>
                                                <div className="col-2"></div>
                                                <div className="col-6 col-md-6 col-lg-5 mt-3 mb-1">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="IsStolen"
                                                            // checked={value?.IsStolen || false}
                                                            // onChange={handleChange1}
                                                            id="flexCheckStolen"
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckStolen">
                                                            Property Destroyed
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Reason Code</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-1 ">
                                                    <Select
                                                        name='LossCodeID'
                                                        styles={customStylesWithOutColor}
                                                        // value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                        // options={propertyLossCodeData}
                                                        onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                                                        isClearable
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Officer</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-1 ">
                                                    <Select
                                                        name='InvestigatorID'
                                                        // value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.InvestigatorID)}
                                                        isClearable
                                                        // options={agencyOfficerDrpData}
                                                        styles={customStylesWithOutColor}
                                                        onChange={(e) => ChangeDropDown(e, 'InvestigatorID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>

                                        {/* ARTICLE   */}
                                        {
                                            value.PropertyCategoryCode === 'A' ?
                                                <div className="col-12 col-md-12 col-lg-12 mt-1 p-0" >
                                                    <fieldset>
                                                        <legend>Article</legend>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                <label htmlFor="" className='new-label'>Serial Id</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Brand</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2  mt-1 text-field">
                                                                <input type="text" name='Brand' id='Brand' maxLength={20} value={value?.Brand} onChange={HandleChanges} className='' required />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {/* Others */}
                                        {
                                            value.PropertyCategoryCode === 'O' ?
                                                <div className="col-12 col-md-12 col-lg-12 pt-2 p-0" >
                                                    <fieldset>
                                                        <legend>Other</legend>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                <label htmlFor="" className='new-label'>Brand</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" name='Brand' id='Brand' value={value?.Brand} onChange={HandleChanges} className='' required />
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                <label htmlFor="" className='new-label'>Serial Id</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {/* Security */}
                                        {
                                            value.PropertyCategoryCode === 'S' ?
                                                <div className="col-12 col-md-12 col-lg-12 pt-2 p-0" >
                                                    <fieldset>
                                                        <legend>Security</legend>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3 col-lg-2  mt-2">
                                                                <label htmlFor="" className='new-label'>Serial Id</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required />
                                                            </div>

                                                        </div>
                                                    </fieldset>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {/* Weapon */}
                                        {
                                            value.PropertyCategoryCode === 'G' ?
                                                <div className="col-12 col-md-12 col-lg-12 pt-2 p-0" >
                                                    <fieldset>
                                                        <legend>Weapon</legend>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                <label htmlFor="" className='new-label'>Serial Id</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field  mt-1">
                                                                <input type="text" name='SerialID' id='SerialID' value={value?.SerialID} onChange={HandleChanges} className='' required />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                :
                                                <>
                                                </>
                                        }
                                        {/* Boat */}
                                        {
                                            value.PropertyCategoryCode === 'B' ?
                                                <div className="col-12 col-md-12 col-lg-12 p-0" >
                                                    <fieldset>
                                                        <legend>Boat</legend>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3 col-lg-1  mt-2 ">
                                                                <label htmlFor="" className='new-label'>Reg. State</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2  mt-1 ">
                                                                <Select
                                                                    name='RegistrationStateID'
                                                                    styles={colourStyles}
                                                                    value={stateDrpData?.filter((obj) => obj.value === value?.RegistrationStateID)}
                                                                    options={stateDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'RegistrationStateID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                            </div>


                                                            <div className="col-3 col-md-3 col-lg-1  mt-2">
                                                                <label htmlFor="" className='new-label'>Reg. No</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" name='RegistrationNumber' id='RegistrationNumber' value={value?.RegistrationNumber} maxLength={10} onChange={HandleChanges} className='' required />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                :
                                                <>
                                                </>
                                        }
                                        {/* drug */}
                                        {
                                            value.PropertyCategoryCode === 'D' ?

                                                <div className="col-12 col-md-12 pt-2 p-0" >
                                                    <fieldset >
                                                        <legend>Drug</legend>
                                                        <div className="row">
                                                            <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                <label className='new-label'>Suspected&nbsp;Drug&nbsp;Type</label>
                                                            </div>
                                                            <div className="col-3 col-md-3  col-lg-4 mt-1">
                                                                <Select
                                                                    name='SuspectedDrugTypeID'
                                                                    styles={colourStyles}
                                                                    value={suspectedDrugDrpData?.filter((obj) => obj.value === value?.SuspectedDrugTypeID)}
                                                                    isClearable
                                                                    options={suspectedDrugDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'SuspectedDrugTypeID')}
                                                                    placeholder="Select..."
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                <label className='new-label'>Measurement Type</label>
                                                            </div>
                                                            <div className="col-3 col-md-3  col-lg-4 mt-1">
                                                                <Select
                                                                    name='DispositionID'
                                                                    value={measureTypeDrpData?.filter((obj) => obj.value === value?.DispositionID)}
                                                                    styles={colourStyles}
                                                                    options={measureTypeDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'DispositionID')}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                            </div>
                                                            <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                <label className='new-label'>Estimated Drug Qty</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-4 mt-1 text-field">
                                                                <input type="text" maxLength={9} name='EstimatedDrugQty' id='EstimatedDrugQty' value={value?.EstimatedDrugQty} onChange={HandleChanges} className='' required autoComplete='off' />
                                                            </div>
                                                            <div className="col-3 col-md-3  col-lg-2 mt-2">
                                                                <label className='new-label'>Fraction Drug Qty</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-4 mt-1 text-field">
                                                                <input type="text" maxLength={9} name='FractionDrugQty' id='FractionDrugQty' value={value?.FractionDrugQty} onChange={HandleChanges} className='' required autoComplete='off' />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                :
                                                <>
                                                </>
                                        }
                                        <fieldset >
                                            <legend>Property Owner</legend>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Last Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type='text' name="LastName" id='LastName' value={value?.LastName} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>First Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type='text' name="FirstName" id="FirstName" value={value?.FirstName} onChange={handlChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2 px-0">
                                                    <label htmlFor="" className='new-label px-0'>Middle Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field ">
                                                    <input type='text' name="MiddleName" id='MiddleName' value={value?.MiddleName} onChange={handlChange} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 text-right mt-1 mb-2">
                                <button className="btn btn-sm bg-green text-white px-2 py-1"
                                    onClick={() => { getPropertySearch(); }}>Show Report</button>
                                <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { Reset(); }}>Clear</button>
                                <Link to={'/Reports'}>
                                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                verifyReport ?

                    reportedData?.Property?.length > 0 ?
                        <>
                            <div className="col-12 col-md-12 col-lg-12 pt-2  px-2">
                                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                    <p className="p-0 m-0 d-flex align-items-center">Property Master Report</p>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Link to={''} onClick={() => { printForm(); }} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                            <i className="fa fa-print"></i>
                                        </Link>
                                        {/* <Link to={''} className="btn btn-sm bg-green  text-white px-2 py-0"  >
                                        <i className="fa fa-file"></i>
                                    </Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="container mt-1" ref={componentRef} >
                                <div className="row" style={{ border: '1px solid #80808085' }}>
                                    <>
                                        <div className="col-4 col-md-3 col-lg-2 pt-1">
                                            <div className="main">
                                                {/* <img src={reportedData?.Agency_Photo} className="img-fluid" alt style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} /> */}
                                                <div className="img-box" >
                                                    <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7  col-md-7 col-lg-9 mt-2">
                                            <div className="main">
                                                <h5 className='text-dark text-bold'>{reportedData?.Agency_Name}</h5>
                                                <p className='text-p'>Address: <span className='new-span pl-2'>{reportedData?.Agency_Address1}</span></p>
                                                <div className='d-flex '>
                                                    <p className='text-p'>State: <span className='new-span ml-2'>{reportedData?.StateName}</span>
                                                    </p>
                                                    <p className='text-p ml-5 pl-1'>City: <span className='new-span'>{reportedData?.CityName}</span>
                                                    </p>
                                                    <p className='text-p ml-2'>Zip: <span className='new-span'>{reportedData?.ZipId}</span>
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='text-p'>Phone: <span className='new-span'>{reportedData?.Agency_Phone}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                    {
                                        reportedData?.Property?.length > 0 ?
                                            <>
                                                {
                                                    reportedData?.Property?.map((obj) => (
                                                        <>
                                                            <div className="col-12">
                                                                <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                                            </div>


                                                            <div className="container" style={{ pageBreakAfter: 'always' }}>
                                                                <h5 className=" text-white text-bold bg-green text-center py-1 px-3">Property Information:{obj?.PropertyNumber}</h5>
                                                                <div className="table-responsive" >
                                                                    <table className="table table-bordered" >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Property Number:</h6>
                                                                                    <p className='text-list'>{obj?.PropertyNumber}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Incident Number</h6>
                                                                                    <p className='text-list'>{obj?.IncidentNumber}</p>
                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Reported Date/Time:</h6>
                                                                                    <p className='text-list'>{obj?.ReportedDtTm ? getShowingDateText(obj?.ReportedDtTm) : null}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Category:</h6>
                                                                                    <p className='text-list'>{obj?.Category_Description}</p>
                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Classification:</h6>
                                                                                    <p className='text-list'>{obj?.Classification_Description}</p>
                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Reason Code:</h6>
                                                                                    <p className='text-list'>{obj?.LossCode_Description}</p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {
                                                                    JSON.parse(obj?.ReportPropertyInventory)?.length > 0 ?
                                                                        <>
                                                                            <div className="table-responsive" >
                                                                                <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                    <p className="p-0 m-0 d-flex align-items-center">Storage Location Information:</p>
                                                                                </div>

                                                                                <table className="table " >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className='' style={{ width: '100px' }}>Property Number</th>
                                                                                            <th className='' style={{ width: '100px' }}>Activity Date/Time</th>
                                                                                            <th className='' style={{ width: '100px' }}>Activity Type</th>
                                                                                            <th className='' style={{ width: '100px' }}>Officer</th>
                                                                                            <th className='' style={{ width: '100px' }}>Property Room Person</th>
                                                                                            <th className='' style={{ width: '100px' }}>Location</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody >
                                                                                        {
                                                                                            JSON.parse(obj?.ReportPropertyInventory)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} >
                                                                                                        <td className='text-list' style={{ width: '100px' }}>{item.PropertyNumber}</td>
                                                                                                        <td className='text-list' style={{ width: '100px' }}>{item?.ReceiveDate ? getShowingDateText(item?.ReceiveDate) : null}</td>
                                                                                                        <td className='text-list' style={{ width: '100px' }}>{item.ActivityType}</td>
                                                                                                        <td className='text-list' style={{ width: '100px' }}>{item.Officer_Name}</td>
                                                                                                        <td className='text-list' style={{ width: '100px' }}>{item.RoomPerson}</td>
                                                                                                        <td className='text-list' style={{ width: '100px' }}>{item.location}</td>

                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }

                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                    :
                    <>
                    </>
            }
        </div>
    )
}

export default PropertyInventoryReport